import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18NService } from 'app/service/i18n.service';
import { EventsService } from 'app/service/events.service';
import { TextSearchService } from 'app/service/text-search.service';
import * as moment from 'moment';
import '../../../styles/components/events.scss';

@Component({
    templateUrl: './event-thumbnail.component.html',
    encapsulation: ViewEncapsulation.None,
    selector: 'event-thumbnail'
})

export class EventThumbnailComponent implements OnInit {

    @Input() event : any = {};
    @Input() width: number;

    constructor(private route: ActivatedRoute, private i18nService: I18NService, private eventsService: EventsService) {
    }

    ngOnInit() {
      this.event.startDateFormatted = this._formatDate(this.event.fields.startDate);
      this.event.endDateFormatted = this._formatDate(this.event.fields.endDate);
      this.event.calendarLink = this._getCalendarLink();
      this.event.url = document.location.origin + '/' + this.event.sys.id;
      this.event.sanitizedTitle = this._sanitizeString(this.event.fields.title);
      this.event.sanitizedDescription = this._sanitizeString(this.event.fields.description);
    }

    _getCalendarLink() {
      let calendarLink = 'http://www.google.com/calendar/event?action=TEMPLATE';
      calendarLink += '&text=' + this._formatString(this._sanitizeString(this.event.fields.title));
      calendarLink += '&dates=' + this._convertDate(this.event.fields.startDate);
      if (this.event.fields.endDate) {
        calendarLink += '/' + this._convertDate(this.event.fields.endDate);
      } else {
        calendarLink += '/' + this._addDate(this.event.startDate);
      }
      calendarLink += '&details=' + this._formatString(this._sanitizeString(this.event.fields.title));
      calendarLink += '&location=' + this._formatString(this._sanitizeString(this.event.fields.location));
      return calendarLink;
    }

    _convertDate(d) {
      return moment.utc(d).format('YYYYMMDDTHHmmss') + 'Z';
    }

    _addDate(d) {
      return moment.utc(d).add('years', 5).format('YYYYMMDDTHHmmss') + 'Z';
    }

    _formatDate(dateString) {
      var d = new Date(dateString);
      return moment(dateString).format('MMMM DD, YYYY');
    }

    _sanitizeString(s) {
      return s.replace(/<(.|\n)*?>/g, '');
    }

    _formatString(s) {
      return encodeURI(s.replace(/<(.|\n)*?>/g, ''));
    }

}
