import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import '../../../../styles/components/home-section.scss';

@Component({
  templateUrl: './home-section.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'home-section'
})

export class HomeSectionComponent {
 url = ""
  @Input() section : any;
  @Input() width : number;

  constructor() {}

  ngOnInit(){
    if(this.section.backgroundImage && this.section.backgroundImage.fields){
      this.url = this.section.backgroundImage.fields.file.url
    }
  }

}
