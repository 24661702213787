import { Component, OnInit, Input, OnChanges ,SimpleChanges  } from '@angular/core';
import { AppConfigService } from 'app/core/services/app-config.service';
import { ScreenSizeService } from 'app/service/screen-size.service';
import { I18NService } from '../../../service/i18n.service';
import {DatasourceService} from '../../../service/datasource.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-graph10',
  templateUrl: './graph10.component.html',
})
export class Graph10Component implements OnInit, OnChanges{

  @Input() country: string;
  @Input() chart: any;
  @Input()   type:any;
  baseUrl: string;
  hostUrl: string;
  tableauImg: string;
  tableauName: string;
  tableauScript: any = null;
  tableauDiv: any = null;subcategories: Array<any> = [];
  allCharts: Array<any> = [];
  charts: Array<any> = [];
  loading: boolean = false;
  showTableau: boolean = false;
  recentlyOpen: boolean = true;
  activeLang: string;
  selectedChart: any;
  selectYear: any;
  selectIndicator:  Array<any> = [];
  years: any;
  showRegion: Boolean = false;

  indicators: Array<any> = [];
  indPyear: any;
  countPyear: Array<any> = [];
  countries: Array<any> = [];
  booleans: Array<any> = [];
  originalPercentages: Array<any> = [];
  originalNumerics: Array<any> = [];
  originalBooleans: Array<any> = [];
  listCountry: any;
  showIndicator: Boolean = true;
  ShowCountries: Boolean =true;
  showYear: Boolean = true;

  constructor(
    private appConfigService: AppConfigService,
    private screenSizeService: ScreenSizeService,
    private i18nService: I18NService,
    private datasourceService: DatasourceService
  ) { }


  ngOnChanges(changes: SimpleChanges){
    this.initialization();  
  }
  initialization(){
    this.loading = true;
    this.activeLang = this.i18nService.getActiveLang();
    this.showTableau = false;
    this.recentlyOpen = true;
    this.baseUrl = this.appConfigService.appConfig['tableauConfig']['baseUrl'];
    let scriptElement = document.createElement('script');
    scriptElement.src = this.appConfigService.appConfig['tableauConfig']['src'];
    this.tableauScript = scriptElement;
    this.tableauDiv = document.getElementById('tableau11');
    if (!this.country) {
      this.charts = this.allCharts.filter(c => c && c['global'])
    } else {
      this.charts = this.allCharts.filter(c => c && !c['global'])
    }
    
     this.charts.forEach((value)=>{
      value.id =Math.floor(Math.random() * 10 + 1);
      value.graph= value.title.slice(0,8)
    })
  if(this.chart.name.includes('numeric')){
    this.type = 'numeric';
  }
  else{
    this.type = 'percentage';
  }
  
  this.getAllIndicators();
    this.callTableau();
    setTimeout(() => {
      this.loading = false;
      
    }, 1000);
  }

  ngOnInit() {}

  callTableau() {
  let name = this.chart.name;
    this.hostUrl = this.appConfigService.appConfig['tableauConfig']['hostUrl'];
    this.tableauName = name;
    let iframeElement = this.tableauDiv.getElementsByTagName('iframe')[0];
    if (!iframeElement ) {
      let vizElement = this.tableauDiv.getElementsByTagName('object')[0];
      if (!vizElement) {
        this.tableauDiv = document.getElementById('tableau11');
        vizElement = this.tableauDiv.getElementsByTagName('object')[0];
      
      }
      vizElement.style.width='100%';
      if (this.screenSizeService.isLg() || this.screenSizeService.isXl()) {
        vizElement.style.height=(400) + 'vh';
         vizElement.style.height='800px';
        vizElement.style.height=(this.tableauDiv.offsetWidth*0.75)+'px';
      } else {
        vizElement.style.height=(400)+'px';
      }
      vizElement.parentNode.insertBefore(this.tableauScript, vizElement);
      this.recentlyOpen = false;
    } else {
      let newHostUrl = this.baseUrl + this.tableauName + '?:embed=y&:showVizHome=no&:host_url=';
     
      newHostUrl += this.hostUrl + '&:embed_code_version=3&:tabs=no&:toolbar=no&:animate_transition=yes&:display_static_image=yes';
      newHostUrl += '&:display_spinner=yes&:display_overlay=yes&:display_count=yes&:linktarget=_blank';
      newHostUrl += '&Language=' + (this.activeLang === 'fr' ? 'French' : 'English');
      newHostUrl += '&:loadOrderID=0';
      if (this.country) {
        newHostUrl += '&Country=' + this.country;
      }
      if (this.selectYear){
        newHostUrl += '&Year(Year)=' + this.selectYear;
      }
      if (this.selectIndicator){
       let ind =[];
       this.selectIndicator.forEach((val)=>{
         
            ind.push(val.replace('%', '%25'));
            console.log(val);
            
       })
       console.log(ind.toString());
        newHostUrl += '&Indicator=' +ind;
      }

      iframeElement.setAttribute('src', newHostUrl);
    }

    setTimeout(() => {
      this.showTableau = true;
    }, 3000);
   
  }
  clearFilters(){
    this.indPyear=[];
    this.selectIndicator=null;
    this.countPyear=[];
  }

  getAllIndicators(){
    this.datasourceService.getAllIndicatorCharts(this.activeLang)
    .then((response)=>{
      
      this.indPyear = _.where(response, {dataType: 'percentage', type:'national'})
      console.log(this.indPyear);
    })
  }



}
