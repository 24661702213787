import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { KeyDocumentsComponent } from './key-documents.component';
import { RouterModule } from '@angular/router';
@NgModule({
  // Components, Pipes, Directive
  declarations: [
    KeyDocumentsComponent
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],

  exports: [
    KeyDocumentsComponent
  ]
})

export class KeyDocumentsModule {

}
