import { Injectable } from '@angular/core'
import { ContentfulService } from 'app/service/contentful.service';

@Injectable()
export class PartnerLogoSliderService {

  constructor(private contentfulService: ContentfulService) {
  }

  get() {
    // Return only the first element because there arent more
    return this.contentfulService.getEntries('partnerLogoSlider')
      .then((logos) => logos.length ? logos[0].fields.partnerLogos.map((item) => item.fields) : []);
  }

}
