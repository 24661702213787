import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/core/services/app-config.service';
import { I18NService } from 'app/service/i18n.service';
import { DatasourceService } from 'app/service/datasource.service';
import { ContentfulService } from 'app/service/contentful.service';
import * as moment from 'moment';

@Injectable()
export class EventsService {
  now: any;
  pageSize: number;
  countries: Array<string>;
  categories: Array<string>;

  constructor(
    appConfigService: AppConfigService,
    private contentfulService: ContentfulService,
    private i18nService: I18NService,
    private dataSourceService: DatasourceService
  ) {
    //this.now = moment(new Date())
    this.now = moment();
    this.pageSize = appConfigService.appConfig['pagination']['eventsMaxPageSize'];
  }

  get() {
    return this.contentfulService.getEntries('event').then((events) => events.map((e) => this._checkImage(e)));
  }

  getById(id) {
    return this.contentfulService.getEntry('event', {'sys.id': id}).then((event) => this._checkImage(event));
  }
  getBlogById(id) {
    return this.contentfulService.getEntry('blog', {'sys.id': id}).then((blog) => this._checkImage(blog));
  }
  getBlogByUrl(url) {
    return this.contentfulService.getEntry('blog', {'fields.urlSlug': url}).then((blog) => this._checkImage(blog));
  }


  /*
        This function get the list of all events from Contentful and then
        filter and sort them based on their endDate (it is null or greater than now).

        NOTE:
        This is done this way (instead of a Contentful query) because the
        Contentful API does not allow queries with null values
    */
  getUpcomingEvents(country?: string, skip?: number, amount?: number) {
    let query = {};
    if (country) {
      //query['fields.country[in]'] = country + ',' + 'GLOBAL';
      query['fields.country[in]'] = country;
    }
    const todayDate = moment(new Date()).format().substr(0, 10);
    query['fields.startDate[gt]'] = todayDate;
    query['limit'] = amount || undefined; // To not damage pagination due to filtering after GET
    query['skip'] = skip || 0;
    return this.contentfulService
      .getEntries('event', query)
      .then((events) => {
        return events
          .map((e) => {
            const endDate = e.fields.endDate;
            if (!endDate) {
              return e;
            } else if (endDate && this.now.isBefore(endDate, 'day')) {
              return e;
            }
          })
          .filter((e) => e)
          .sort((a, b) => {
            const aStartDate = moment(a.fields.startDate);
            const bStartDate = moment(b.fields.startDate);
            if (bStartDate.isSame(aStartDate, 'day')) {
              return 0;
            } else if (bStartDate.isBefore(aStartDate, 'day')) {
              return -1;
            } else {
              return 1;
            }
          });
      })
      .then((events) => events.map((e) => this._checkImage(e)));
  }

  getOngoingEvents(country?: string, skip?: number, amount?: number) {
    const query = {};
    const todayDate = moment(new Date()).format().substr(0, 10);

    if (country) {
      query['fields.country[in]'] = country;
    }
    query['limit'] = amount || undefined; // To not damage pagination due to filtering after GET
    query['skip'] = skip || 0;
    query['order'] = '-fields.endDate';
    query['fields.startDate[lte]'] = todayDate;
    // query['fields.endDate[gte]'] =  todayDate;
    return this.contentfulService
      .getEntries('event', query)
      .then((events) => {
        return events
          .map((e) => {
            const endDate = e.fields.endDate;
            if (!endDate) {
              return e;
            } else if (endDate && endDate >= todayDate) {
              return e;
            }
          })
          .filter((e) => e)
          .sort((a, b) => {
            const aStartDate = moment(a.fields.startDate);
            const bStartDate = moment(b.fields.startDate);
            return bStartDate.isSame(aStartDate, 'day') ? 0 : bStartDate.isBefore(aStartDate, 'day') ? -1 : 1;
          });
      })
      .then((events) => events.map((e) => this._checkImage(e)));
  }

  getPreviousEvents(country?: string, skip?: number, amount?: number) {
    let query = {};
    query['fields.endDate[lte]'] = this.now.format();
    query['order'] = '-fields.startDate';
    query['limit'] = amount || this.pageSize;
    query['skip'] = skip || 0;
    if (country) {
      //query['fields.country[in]'] = country + ',' + 'GLOBAL';
      query['fields.country[in]'] = country;
    }
    return this.contentfulService.getEntries('event', query).then((events) => events.map((e) => this._checkImage(e)));
  }
//News Events
  getNewsEvents(country?: string, skip?: number, amount?: number) {
    let query = {};
    query['limit'] = amount || this.pageSize;
    query['skip'] = skip || 0;
    if (country) {
      query['fields.country[in]'] = country;
    }
   return this.contentfulService.getEntries('blog', query).then((blogs) => {return blogs;} );
  }

  searchEvents(query, skip?) {
    let eventsQuery = {};
    if (query.date) {
      if (query.ongoing) {
        eventsQuery['fields.startDate[lte]'] = query.date;
        eventsQuery['fields.endDate[gte]'] = query.date;
        eventsQuery['order'] = '-fields.startDate';
      } else {
        eventsQuery['fields.endDate[lte]'] = query.date;
        eventsQuery['order'] = '-fields.startDate';
      }
    }
    if (query.category) {
      eventsQuery['fields.category'] = query.category;
    }
    if (query.country) {
      //eventsQuery['fields.country[in]'] = query.country + ',' + 'GLOBAL'
      eventsQuery['fields.country[in]'] = query.country;
    }
    if (query.text) {
      eventsQuery['query'] = query.text;
    }
    eventsQuery['limit'] = this.pageSize;
    eventsQuery['skip'] = skip || 0;

    return this.contentfulService.getEntries('event', eventsQuery).then((events) => events.map((e) => this._checkImage(e)));
  }

  _getFields(field: string) {
    let query = {select: 'fields.' + field};
    return this.contentfulService.getAllEntries('event', query).then((events) => {
      return events
        .map((e) => e.fields)
        .filter((e) => e)
        .map((e) => e[field]);
    });
  }

  getCountries() {
    if (!this.countries) {
      return this._getFields('country')
        .then((res) => {
          let countries = [];
          res.forEach((country) => {
            if (country.indexOf(',') !== -1) {
              let aux = country.split(',');
              aux.forEach((c) => {
                countries.push(c);
              });
            } else {
              countries.push(country);
            }
          });
          countries = this._unique(countries).sort((a, b) => {
            if (a > b) return 1;
            else if (a < b) return -1;
            return 0;
          });
          const globalIndex = countries.indexOf('GLOBAL');
          if (globalIndex > -1) {
            countries.splice(globalIndex, 1);
            countries.unshift('GLOBAL');
          }
          return countries;
        })
        .then((countries) => {
          return this.dataSourceService.getCountryNames(countries, this.i18nService.getActiveLang()).then((dsCountries) => {
            this.countries = dsCountries;
            return this.countries;
          });
        });
    } else {
      return Promise.resolve(this.countries);
    }
  }

  getCategories() {
    if (this.categories) {
      return this._getFields('category').then((res) => {
        this.categories = this._unique(res);
        return this.categories;
      });
    } else {
      return Promise.resolve(this.categories);
    }
  }

  _unique(arr: Array<string>): Array<string> {
    let newArr: Array<string> = [];
    arr.forEach((e) => {
      if (e && newArr.indexOf(e) === -1 && e.trim() !== '') {
        newArr.push(e);
      }
    });
    return newArr;
  }

  _checkImage(event: any): any {
    if (event && event.fields) {
      if (event.fields.photo) {
        event.fields.photo = event.fields.photo && event.fields.photo.fields ? event.fields.photo : null;
      }
      return event;
    }
    if (event) {
      if (event.photo) {
        event.photo = event.photo && event.photo.fields ? event.photo : null;
      }
      return event;
    }
    return null;
  }
}
