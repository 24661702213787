import { Component, OnInit, Input } from '@angular/core';
import { ResourceService } from 'app/service/resource.service';
import { ScreenSizeService } from '../service/screen-size.service';
import { AppConfigService } from 'app/core/services/app-config.service';
import { EventsService } from '../service/events.service';
import { I18NService } from '../service/i18n.service';
import '../../styles/components/keydocs.scss';

@Component({
  templateUrl: './key-documents.component.html',
  selector: 'key-documents'
})

export class KeyDocumentsComponent implements OnInit {

  @Input() type: string;
  @Input() eventImgUrl: string
  @Input() resourceImgUrl: string
  @Input() newsImage: string

  resources: any
  events: any
  colWidth: number;
  sizes: any;
  blogs: any;
  resourcesPageSize: number;
  activeLang: string;
  constructor(
    private resourceService: ResourceService,
    private screenSizeService: ScreenSizeService,
    private appConfigService: AppConfigService,
    private eventsService: EventsService,
   private i18nService: I18NService,
  ) {
    this.sizes = this.appConfigService.appConfig['sizes'];
    this.resourcesPageSize = appConfigService.appConfig['pagination']['eventsMaxPageSize'];
  }

  ngOnInit() {
    this.activeLang=this.i18nService.getActiveLang();

    this.colWidth = this._getColWidth()
    this.resourceService.get(null, null, this.resourcesPageSize)
      .then((resources) => {
        
        this.resources = resources.items.map((r) => {
          r.sanitizedTitle = this._sanitizeString(r.fields.title);
          return r;
        });
       

      })
      .catch((error) => {
        console.log('error', error);
      });
    this.eventsService.getUpcomingEvents()
      .then((events) => {     
         this.events = events.slice(0, this.resourcesPageSize).map((e) => {        
          
          e.sanitizedTitle = this._sanitizeString(e.fields.title);
          return e;
        });
        this.events = this.events.sort(this.compare);
      })
      .catch((err) => {
        console.error('error', err)
      });
      this.eventsService.getNewsEvents()
      .then((blogs) => {
        this.blogs = blogs.slice(0, this.resourcesPageSize).map((e) => {           
          e.sanitizedTitle = this._sanitizeString(e.fields.title);
          return e;
        });
        this.blogs = this.blogs.sort(this.comparePDate);
       
       
      })
      .catch((err) => {
          console.error('error', err)
      });
      
  }

  _sanitizeString(s) {
    return s.replace(/<(.|\n)*?>/g, '');
  }

  _getColWidth() {
    let sizes = this.sizes
    if (this.screenSizeService.isXl()) {
      return sizes.containerWidthXl / 3
    }
    else if (this.screenSizeService.isLg()) {
      return sizes.containerWidthLg / 3
    }
    else if (this.screenSizeService.isMd()) {
      return sizes.containerWidthMd / 2
    }
    else if (this.screenSizeService.isSm()) {
      return sizes.containerWidthSm
    }
    else {
      return this.screenSizeService.getWidth()
    }
  }


   compare(a, b) {
    const yearA = a.fields.startDate
    const yearb = b.fields.startDate

  
    let comparison = 0;
    if (yearA > yearb) {
      comparison = 1;
    } else if (yearA < yearb) {
      comparison = -1;
    }
    return comparison;
  }
  
  comparePDate(a, b) {
    const publishedDateA = a.fields.publishedDate
    const publishedDateb = b.fields.publishedDate

  
    let comparison = 0;
    if (publishedDateb > publishedDateA) {
      comparison = 1;
    } else if (publishedDateb < publishedDateA) {
      comparison = -1;
    }
    return comparison;
  }
  

}
