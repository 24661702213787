import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import '../../../styles/components/countries.scss';
import * as _ from 'underscore';

@Component({
  templateUrl: './countries-modal.component.html',
  selector: 'countries-modal'
})

export class CountriesModalComponent implements OnInit {


   @Input() data : any

  constructor( ) {
  }

  ngOnInit() {
  
  }

  goToRoute(){
    
  }
}
