import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MetaService } from 'app/service/meta.service';
import { HomepageService } from '../service/homepage.service';
import { ScreenSizeService } from '../service/screen-size.service';
import { I18NService } from '../service/i18n.service';

@Component({
  templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {

  slides = [];
  sections = [];
  height: number;
  width: number;
  wyskEventsImageUrl: string;
  wyskResourcesImageUrl: string;
  wyskNewsImageUrl: string;
  constructor(
    private homepageService: HomepageService,
    private titleService: Title,
    private metaService: MetaService,
    private screenSizeService: ScreenSizeService,
    private i18NService: I18NService
  ) {
  }

  ngOnInit() {
    this.height = this.screenSizeService.getHeight();
    this.width = this.screenSizeService.getWidth();
    this.i18NService.getSingleTranslation('share.soam.mainText')
      .then((translation) => {
        this.titleService.setTitle(translation);
        this.metaService.resetMetas();
        this.metaService.addMetas(
          translation,
          translation,
        );
      });
    this.homepageService.get()
      .then((home) => {
        this.slides = home.slides.map(slide => slide.fields);
        this.sections = home.sections.map(section => section.fields);
        this.wyskEventsImageUrl = home.wyskEventsImage.fields.file.url;
        this.wyskResourcesImageUrl = home.wyskResourcesImage.fields.file.url
        this.wyskNewsImageUrl =home.wyskNewsImage.fields.file.url
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

}
