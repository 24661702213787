import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ShareButtonModule } from '@ngx-share/button';
import { MarkdownModule } from 'ngx-markdown';

import { DocumentTableComponent } from './document-table/document-table.component';
import { EventThumbnailComponent } from 'app/shared/event-thumbnail/event-thumbnail.component';
import { DatabarComponent } from 'app/shared/databar/databar.component';
import { CountryListComponent } from 'app/shared/country-list/country-list.component';
import { ChartComponent } from 'app/shared/chart/chart.component';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { CountriesModalComponent } from './country-list/countries-modal.component';
import { OrederByPipe} from './../pipes/order.pipe';
import { BlogComponent } from './blog/blog.component'
//import { ModalModule } from '@ng-bootstrap/ng-bootstr';  



@NgModule({
  declarations: [
    DocumentTableComponent,
    EventThumbnailComponent,
    DatabarComponent,
    CountryListComponent,
    ChartComponent,
    ModalComponent,
    CountriesModalComponent,
    OrederByPipe,
    BlogComponent
    
  ],
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    ShareButtonModule,
    MarkdownModule.forChild()
  ],
  providers: [
  ],
  exports: [
    TranslateModule,
    DocumentTableComponent,
    EventThumbnailComponent,
    DatabarComponent,
    CountryListComponent,
    ChartComponent,
    ModalComponent,
    MarkdownModule,
    CountriesModalComponent,
    BlogComponent
    
  ]
})

export class SharedModule {

}
