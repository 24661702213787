import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18NService } from 'app/service/i18n.service';
import { EventsService } from 'app/service/events.service';
import { TextSearchService } from 'app/service/text-search.service';
import * as moment from 'moment';
import '../../../styles/components/events.scss';

@Component({
  selector: 'blog-thumbnail',
  templateUrl: './blog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class BlogComponent implements OnInit {
  @Input() blog : any = {};
  @Input() width: number;
  activeLang: string;

  constructor(private route: ActivatedRoute, private i18nService: I18NService, private eventsService: EventsService) { }

  ngOnInit() {

    this.activeLang=this.i18nService.getActiveLang();
    this.blog.sanitizedTitle =this._sanitizeString(this.blog.fields.title);
    this.blog.publishedDateFormatted = this._formatDate(this.blog.fields.publishedDate);
    this.blog.calendarLink = this._getCalendarLink();
    this.blog.url = document.location.origin + '/' + this.blog.fields.urlSlug
    this.blog.sanitizedTitle = this._sanitizeString(this.blog.fields.title);
    this.blog.concatContent= this._createContent(this.blog.fields.content.content);
  }

  _getCalendarLink() {
    let calendarLink = 'http://www.google.com/calendar/event?action=TEMPLATE';
    calendarLink += '&text=' + this._formatString(this._sanitizeString(this.blog.fields.title));
    calendarLink += '&dates=' + this._convertDate(this.blog.fields.startDate);
    if (this.blog.fields.endDate) {
      calendarLink += '/' + this._convertDate(this.blog.fields.endDate);
    } else {
      calendarLink += '/' + this._addDate(this.blog.startDate);
    }
    calendarLink += '&details=' + this._formatString(this._sanitizeString(this.blog.fields.title));
    calendarLink += '&location=' + this._formatString(this._sanitizeString(this.blog.fields.location));
    return calendarLink;
  }

  _convertDate(d) {
    return moment.utc(d).format('YYYYMMDDTHHmmss') + 'Z';
  }

  _addDate(d) {
    return moment.utc(d).add('years', 5).format('YYYYMMDDTHHmmss') + 'Z';
  }

  _formatDate(dateString) {
    var d = new Date(dateString);
    return moment(dateString).format('MMMM DD, YYYY');
  }

  _sanitizeString(s) {
    return s.replace(/<(.|\n)*?>/g, '');
  }

  _formatString(s) {
    return encodeURI(s.replace(/<(.|\n)*?>/g, ''));
  }
  _createContent (c){
    var content = '';
    c.array.forEach(value => {
      content = content + value.value
    });
    return this._sanitizeString(content);
  }
}


