import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/core/services/app-config.service';
import { I18NService } from 'app/service/i18n.service';
import { DatasourceService } from 'app/service/datasource.service';
import { ContentfulService } from 'app/service/contentful.service';
import * as moment from 'moment';

@Injectable()
export class ResourceService {
  pageSize: number;
  countries: Array<string>;
  types: Array<string>;
  years: Array<string>;
  langs: Array<string>;

  constructor(
    appConfigService: AppConfigService, 
    private contentfulService: ContentfulService, 
    private i18nService: I18NService,
    private dataSourceService: DatasourceService
  ) {
    this.pageSize = appConfigService.appConfig['pagination']['resourcesMaxPageSize'];
  }

  get(country?: string, skip?: number, amount?: number) {
    let query = {}
    if (country) {
      query['fields.country[match]'] = country
    }
    query['limit'] = amount || undefined; // To not mess with the filters obtained from the resources
    query['skip'] = skip || 0;
    query['order'] = '-fields.year';
    return this.contentfulService.getEntriesWithCount('resourceRoot', query)
      .then((resources) => {
        const items = resources.items.map((resource) => {
          resource.fields.image = resource.fields.image && resource.fields.image.fields ? resource.fields.image : null;
          if (resource.fields.documents) {
            resource.fields.documents = resource.fields.documents.map((d) => {
              return d.fields ? d : null;
            }).filter(d => d);
          }
          if (resource.fields.country) {
            resource.fields.country = resource.fields.country.replace(/([,])/g, ',\s')
          }
          return resource;
        }).filter(r => r);
        return { items: items, count: resources.count };
      });
  }

  getById(id) {
    return this.contentfulService.getEntry('resourceRoot', {'sys.id': id})
      .then((resource) => {
        if (resource) {
          resource.image = resource.image && resource.image.fields ? resource.image : null;
          if (resource.country) {
            resource.country = resource.country.replace(/,/g, ', ')
          }
          if (resource.documents) {
            resource.documents = resource.documents.map((d) => {
              return d.fields ? d : null;
            }).filter(d => d);
          }
        }
        return resource;
      });
  }

  _getFields(field: string, contentType?: string) {
    let query = { select: 'fields.' + field };
    if (!contentType) {
      query['order'] = '-fields.year';
    }
    return this.contentfulService.getAllEntries(contentType || 'resourceRoot', query)
      .then((resources) => {
        return resources.map(r => r.fields).filter(r => r).map(r => r[field]);
      });
  }

  getCountries() {
    if (!this.countries) {
      return this._getFields('country')
        .then((res) => {
          let countries = [];
          res.forEach((country) => {
            if (country.indexOf(',') !== -1) {
              let aux = country.split(',');
              aux.forEach(c => {
                countries.push(c);
              });
            } else {
              countries.push(country);
            }
          });
          countries = this._unique(countries);
          const globalIndex = countries.indexOf('GLOBAL');
          if (globalIndex > -1) {
            countries.splice(globalIndex, 1);
            countries.unshift('GLOBAL');
          }
          return countries;
        })
        .then((countries) => {
          return this.dataSourceService.getCountryNames(countries, this.i18nService.getActiveLang())
            .then((dsCountries) => { 
              let countries = [];
              countries = dsCountries.sort((a, b) => {
                if (a.name > b.name) return 1;
                else if (a.name < b.name) return -1;
                return 0;
              });
              const globalIndex = countries.map(c => c.code).indexOf('GLOBAL');
              if (globalIndex > -1) {
                let global = countries.splice(globalIndex, 1)[0];
                countries.unshift(global);
              }
              this.countries = countries; 
              return this.countries; 
            });
        });
    } else {
      return Promise.resolve(this.countries);
    }
  }

  translateIsoCountriesFromResource(countries: string) {
    return this.dataSourceService.getCountryNames(countries.split(',').map(c => c.trim()), this.i18nService.getActiveLang())
      .then((countries) => { return countries.map(c => c.name).filter(c => c).join(', '); });
  }

  getTypes() {
    if (!this.types) {
      return this._getFields('type')
        .then((res) => {
          this.types = this._unique(res);
          return this.types;
        });
    } else {
      return Promise.resolve(this.types);
    }
  }

  getYears() {
    if (!this.years) {
      return this._getFields('year')
        .then((res) => {
          //return this._unique(res.map(d => moment(d, 'YYYY-MM-DD').year().toString()));
          return this._unique(res.filter(d => d > 0).map(d => d.toString()));
        });
    } else {
      return Promise.resolve(this.years);
    }
  }

  getLangs() {
    if (!this.langs) {
      return this._getFields('language', 'resourceDocument')
        .then((res) => {
          this.langs = this._unique(res);
          return this.langs;
        });
    } else {
      return Promise.resolve(this.langs)
    }
  }

  searchResources(query, skip?) {
    let resourcesQuery = {};
    if (query.type) {
      resourcesQuery['fields.type'] = query.type
    }
    if (query.country) {
      resourcesQuery['fields.country[match]'] = query.country
    }
    if (query.year) {
      //resourcesQuery['fields.year[lte]'] = query.year + '-12-31';
      //resourcesQuery['fields.year[gte]'] = query.year + '-01-01';
      resourcesQuery['fields.year'] = query.year;
    }
    /*
    // NOT ALLOWED BY CF
    if (query.language) {
      resourcesQuery['fields.documents.fields.language'] = query.language
    }
    */
    if (query.text) {
      resourcesQuery['query'] = query.text
    }
    resourcesQuery['limit'] = this.pageSize
    resourcesQuery['skip'] = skip || 0
    resourcesQuery['order'] = '-fields.year';
    return this.contentfulService.getEntriesWithCount('resourceRoot', resourcesQuery)
      .then((resources) => {
        const items = resources.items.map((resource) => {
          if (resource.fields.documents) {
            resource.fields.documents = resource.fields.documents.map((d) => {
              return d.fields ? d : null;
            }).filter(d => d);
          }
          if (resource.country) {
            resource.country = resource.country.replace(/([,])/g, ',\s')
          }
          return resource;
        }).filter(r => r);
        return { items: items, count: resources.count };
        /*
        // FILTER LANGUAGES, NOT WORKING PROPERLY
        if (query.language) {
          // If search by language, then a search of the documents must be made.
          // It is done client side because CF does not allow to search multiple linked entries
          return result.filter(r => {
            let hasLanguage = false;
            if (r.fields.documents) {
              r.fields.documents.forEach(d => {
                if (d.fields.language === query.language) {
                  hasLanguage = true;
                }
              });
            }
            return hasLanguage;
          });
        }
        return result;
        */
      });
  }

  _unique(arr: Array<string>): Array<string> {
    let newArr: Array<string> = [];
    arr.forEach((e) => {
      if (e && newArr.indexOf(e) === -1 && e.trim() !== '') {
        newArr.push(e);
      }
    });
    return newArr;
  }

}

