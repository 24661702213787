import { Component, OnInit, Input, OnChanges, EventEmitter , SimpleChanges} from '@angular/core';
import { AppConfigService } from 'app/core/services/app-config.service';
import { ScreenSizeService } from 'app/service/screen-size.service';
import { I18NService } from '../../../service/i18n.service';
import {DatasourceService} from '../../../service/datasource.service';
import * as _ from 'underscore';


@Component({
  selector: 'app-graph1',
  templateUrl: './graph1.component.html',
})
export class Graph1Component implements OnInit, OnChanges {

  @Input() country: string;
  @Input() chart: any;
  @Input() type: any;
  @Input() number: any;
  baseUrl: string;
  hostUrl: string;
  tableauImg: string;
  tableauName: string;
  tableauScript: any = null;
  tableauDiv: any = null;subcategories: Array<any> = [];
  allCharts: Array<any> = [];
  charts: Array<any> = [];
  loading: boolean = false;
  showTableau: boolean = false;
  recentlyOpen: boolean = true;
  activeLang: string;
  selectedChart: any;
  selectYear: any ;
  selectIndicator: any;
  selectedRegion: Array<any> = [];
  years: any;
  showRegion: Boolean = false;
  indicators: Array<any> = [];
  indPyear: any;
  countPyear$: any;
 
  regions: Array<any> = [];
  actualCountries: any;
  countries: Array<any> = [];
  booleans: Array<any> = [];
  originalPercentages: Array<any> = [];
  originalNumerics: Array<any> = [];
  originalBooleans: Array<any> = [];
  listCountry: any;
  showIndicator: Boolean = true;
  ShowCountries: Boolean =true;
  showYear: Boolean = true;
  
  constructor(
    private appConfigService: AppConfigService,
    private screenSizeService: ScreenSizeService,
    private i18nService: I18NService,
    private datasourceService: DatasourceService
  ) { }


  ngOnChanges(changes: SimpleChanges){
    
    if(_.isUndefined(this.type)){
        this.type='numeric'
        this.number=1;
    }
    this.initialization();
  
  }
  ngOnInit() {
  
  }

  initialization(){

    this.loading = true;
    this.activeLang = this.i18nService.getActiveLang();
    this.showTableau = false;
    this.recentlyOpen = true;
    this.selectYear= null
    this.selectIndicator= null
    this.listCountry=null;
    this.indPyear=[];
    this.countPyear$=null;
    this.baseUrl = this.appConfigService.appConfig['tableauConfig']['baseUrl'];
    this.selectYear= this.appConfigService.appConfig['data']['defaultYear'];
    let scriptElement = document.createElement('script');
    scriptElement.src = this.appConfigService.appConfig['tableauConfig']['src'];
    this.tableauScript = scriptElement;
    this.tableauDiv = document.getElementById('tableau3');
    if (!this.country) {
      this.charts = this.allCharts.filter(c => c && c['global'])
    } else {
      this.charts = this.allCharts.filter(c => c && !c['global'])
    }
    
     this.charts.forEach((value)=>{
      value.id =Math.floor(Math.random() * 10 + 1);
      value.graph= value.title.slice(0,8)
    })
   

    this.datasourceService.getChartsYear(this.activeLang).then((years)=>{
      this.years = years;
      
    })
    this.datasourceService.getDataCharts(this.activeLang)
    .then((response)=>{  
      this.indicators=response;
      let ini =   _.findWhere(this.indicators, { y : this.selectYear.toString()});
      
     this.indPyear= _.where(ini.indicators, { type : this.type})
     this.countriesByRegion();
     

    });




    this.callTableau( this.chart);
   
    setTimeout(() => {
      this.loading = false;
      
    }, 1000);
  }

  callTableau(item) {
  let name = item.name; 
    this.hostUrl = this.appConfigService.appConfig['tableauConfig']['hostUrl'];
    this.tableauName = name;
    let iframeElement = this.tableauDiv.getElementsByTagName('iframe')[0];
    if (!iframeElement ) {
      let vizElement = this.tableauDiv.getElementsByTagName('object')[0];
      if (!vizElement) {
        this.tableauDiv = document.getElementById('tableau3');
        vizElement = this.tableauDiv.getElementsByTagName('object')[0];
      }
      vizElement.style.width='100%';
      if (this.screenSizeService.isLg() || this.screenSizeService.isXl()) {
        vizElement.style.height=(400) + 'vh';
         vizElement.style.height='800px';
        vizElement.style.height=(this.tableauDiv.offsetWidth*0.75)+'px';
      } else {
        vizElement.style.height=(400)+'px';
      }
      vizElement.parentNode.insertBefore(this.tableauScript, vizElement);
      this.recentlyOpen = false;
    } else {
      let newHostUrl = this.baseUrl + this.tableauName + '?:embed=y&:showVizHome=no&:host_url=';
     
      newHostUrl += this.hostUrl + '&:embed_code_version=3&:tabs=no&:toolbar=no&:animate_transition=yes&:display_static_image=yes';
      newHostUrl += '&:display_spinner=yes&:display_overlay=yes&:display_count=yes&:linktarget=_blank';
      newHostUrl += '&Language=' + (this.activeLang === 'fr' ? 'French' : 'English');
      newHostUrl += '&:loadOrderID=0';
      if (this.country) {
        newHostUrl += '&Country=' + this.country;
      }
      if (this.selectYear){
        newHostUrl += '&Year(Year)=' + this.selectYear;
      }
      if (this.selectIndicator){
        newHostUrl += '&Indicator=' + this.selectIndicator.replace('%', '%25');
      }
      if (this.selectedRegion && this.selectedRegion[0]!=='all'){
        newHostUrl += '&Region=' + this.selectedRegion.toString();
      }


      iframeElement.setAttribute('src', newHostUrl);
    }

    setTimeout(() => {
      this.showTableau = true;
    }, 3000);
   
  }



 

  getIndicators(year){
    
   this.clearFilters();

   let indicator =   _.findWhere(this.indicators, { y : this.selectYear})
   this.indPyear= _.where(indicator.indicators, { type : this.type})
   this.callTableau(this.chart)
  }

  getCountries($event, region){

    if(_.isUndefined( this.selectedRegion)){
      this.selectedRegion=[];
    }
    this.countPyear$= this.datasourceService.getCountiesByYear($event, this.selectedRegion );
 
      this.callTableau(this.chart);
  }

  countriesNow($event){  
      this.actualCountries= $event;    
  }

  setCountry(country){
   this.country = this.listCountry.toString();
    this.callTableau(this.chart);
  }

  clearFilters(){
    this.indPyear=[];
    this.selectIndicator=null;
  }

  getAllIndicators(){
    this.datasourceService.getAllIndicatorCharts(this.activeLang)
    .then((response)=>{
      this.indPyear = _.where(response, {dataType: this.type, type:'national'})
    })
  }

  countriesByRegion(){
    this.datasourceService.getCountryNamesOfData()
    .then((response)=>{

      response.forEach((value)=>{
        value.all = 'all'
      })
      this.regions= response;
    })
  }


}
