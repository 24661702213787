import { Component, ViewEncapsulation, Input } from '@angular/core';
import { ResourceService } from 'app/service/resource.service';
import '../../../styles/components/resource-library.scss';
import { DatePipe } from '@angular/common';

@Component({
  templateUrl: './document-table.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'document-table'
})

export class DocumentTableComponent {

  @Input() documents : any;
  @Input() country : any;
  @Input() isLoading : boolean;

  constructor() {}

}
