import { Injectable } from '@angular/core';
import { FirebaseDatabaseService } from 'app/service/firebase-database.service';
import { AppConfigService } from 'app/core/services/app-config.service';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as _ from 'underscore';

@Injectable()
export class DatasourceService {
  countryNames: any = null;
  regionNames: any = null;
  excludeIndicators: Array<string> = [];
  subNational: Array<string> = [];

  constructor(appConfigService: AppConfigService, private firebaseDatabaseService: FirebaseDatabaseService) {
    this.excludeIndicators = appConfigService.appConfig['data']['excludeIndicators'];
  }

  /* Gets the dataFields from the database table dataFields and format them */
  getDataFields(): Promise<Object> {
    return this._getData('dataFields').then((data) => {
      let ret = {};
      data.forEach((item) => {
        ret[item['Field']] = item;
      });
      return ret;
    });
  }

  getDataSubcategories(lang: string): Promise<Array<any>> {
    return this.getDataFields().then((dataFields) => {
      const langToGet = lang === 'fr' ? 'Subcategory_FR' : 'Subcategory';
      const datas = Object.keys(dataFields)
        .map((k) => {
          return {
            name: dataFields[k]['Subcategory'],
            title: dataFields[k][langToGet],
            type: dataFields[k]['Type'].toLowerCase()
          };
        })
        .filter((d) => d && d.title && d.name !== '' && d.title !== '' && (d.type === 'national' || d.type === 'both'));
      let subcategories = [];
      datas.forEach((d) => {
        let index = -1;
        subcategories.forEach((sc, i) => {
          if (sc.name === d.name) {
            index = i;
            return false;
          }
        });
        if (index === -1) {
          subcategories.push(d);
        }
      });
      //console.log('subcategories', subcategories);
      return subcategories.sort((a, b) => a.title.localeCompare(b.title));
    });
  }

  getFieldsOfType(lang: string, type: string, country?: string): Promise<Array<any>> {

    return this.getDataFields()
      .then((dataFields) => {
        const langToGet = lang === 'fr' ? 'French Title:' : 'English Title:';
        const datas = Object.keys(dataFields)
          .map((k) => {
            return dataFields[k]['Data Type'].toLowerCase() === type.toLowerCase()
              ? {
                name: k,
                title: dataFields[k][langToGet],
                subcategory: dataFields[k]['Subcategory'],
                type: dataFields[k]['Type'].toLowerCase()
              }
              : null;
          })
          .filter(
            (d) =>
              d &&
              d.title &&
              d.name !== '' &&
              d.title !== '' &&
              (d.type === 'national' || d.type === 'both') &&
              d.subcategory !== '' &&
              this.excludeIndicators.indexOf(d.name) === -1
          );
        return datas;
      })
      .then((datas) => {
        if (country) {
          return this.getCountryData(country).then((countryData) => {
            let filteredDatas = [];
            datas.forEach((d) => {
              countryData.forEach((cd) => {
                let key = d.name;
                if (cd[key] && cd[key] !== '') {
                  let inArray = false;
                  filteredDatas.forEach((fd) => {
                    if (fd.name === d.name) {
                      inArray = true;
                      return false;
                    }
                  });
                  if (!inArray) {
                    filteredDatas.push(d);
                  }
                  return false;
                }
              });
            });
            filteredDatas.forEach((d) => {
              let lastYearOfData = null;
              let filteredByData = countryData
                .map((cd) => {
                  Object.keys(cd).forEach((key) => {
                    let newKey = key.toLowerCase();
                    if (key !== newKey) {
                      cd[newKey] = cd[key];
                      delete cd[key];
                    }
                  });
                  return cd;
                })
                .filter((cd) => cd[d.name] && cd[d.name] !== '');
              filteredByData.forEach((fd) => {
                if (fd['year'] && fd['year'] !== '') {
                  let parsedYear = parseInt(fd['year']);
                  if (parsedYear !== NaN) {
                    if (!lastYearOfData) {
                      lastYearOfData = parsedYear;
                    } else {
                      if (parsedYear > lastYearOfData) {
                        lastYearOfData = parsedYear;
                      }
                    }
                  }
                }
              });
              d['lastYear'] = lastYearOfData;
            });
            return filteredDatas.filter((d) => d['lastYear']);
          });
        } else {
          return this.getGlobalCountryData().then((countryData) => {
            datas.forEach((d) => {
              let lastYearOfData = null;
              let filteredByData = countryData
                .map((cd) => {
                  Object.keys(cd).forEach((key) => {
                    let newKey = key.toLowerCase();
                    if (key !== newKey) {
                      cd[newKey] = cd[key];
                      delete cd[key];
                    }
                  });
                  return cd;
                })
                .filter((cd) => cd[d.name] && cd[d.name] !== '');
              filteredByData.forEach((fd) => {
                if (fd['year'] && fd['year'] !== '') {
                  let parsedYear = parseInt(fd['year']);
                  if (parsedYear !== NaN) {
                    if (!lastYearOfData) {
                      lastYearOfData = parsedYear;
                    } else {
                      if (parsedYear > lastYearOfData) {
                        lastYearOfData = parsedYear;
                      }
                    }
                  }
                }
              });
              d['lastYear'] = lastYearOfData;
            });
            return datas.filter((d) => d['lastYear']);
          });
        }
      });
  }

  /* Get global data (all the countries) from the database table countries */
  getGlobalCountryData(year?: number): Promise<Array<any>> {
    if (!year) {
      return this._getData('countries');
    } else {
      return this._getData('countries').then((data: Array<any>) => this._filterByYear(data, year));
    }
  }

  /* Get all the countries names sorted by regions*/
  getCountryNamesOfData(lang: string = 'en'): Promise<Array<any>> {
    return this.getCountryAndRegionNames()
      .then(() => this._getData('countries'))
      .then((countries: Array<any>) => countries.map((data) => this._getNameAndRegions(data)))
      .then((countries: Array<any>) => this._getNameTranslations(countries, lang))
      .then((countries: Array<any>) => this._noRepeatCountry(countries))
      .then((countries: Array<any>) => this._groupByRegion(countries));
  }

  /* Get a country data from the database table countries */
  getCountryData(country: string, year?: number): Promise<Array<any>> {
    if (!year) {
      return this._getData('countries', 'ISO code', country);
    } else {
      return this._getData('countries', 'ISO code', country).then((data: Array<any>) => this._filterByYear(data, year));
    }
  }

  /* Get a country data from the database table countries */
  getCountryRegionData(country: string, year?: number): Promise<Array<any>> {
    if (!year) {
      return this._getData('subCountries', 'ISO code', country);
    } else {
      return this._getData('subCountries', 'ISO code', country).then((data: Array<any>) => this._filterByYear(data, year));
    }
  }

  /* Get global data from the database table countries and aggregates it */
  getGlobalAggregatedData(): Promise<any> {
    return this._getData('countries').then((data: Array<any>) => this._aggregateData(data));
  }

  /* Get a country data from the database table countries and aggregates it */
  getCountryAggregatedData(country: string): Promise<any> {
    return this._getData('countries', 'ISO code', country).then((data: Array<any>) => this._aggregateData(data));
  }

  /*
   * DEPRECATED - NOT IN USE
   * Get a country data from the database table countries and aggregates it
   */
  getCountryRecentData(country: string): Promise<any> {
    return this._getData('countries', 'ISO code', country).then((data: Array<any>) => this._sortRecentData(data));
  }

  /* Get data from a database table with possible filter */
  private _getData(listName: string, filterKey?: string, filterValue?: string): Promise<Array<any>> {
    return new Promise((resolve) => {
      this.firebaseDatabaseService
        .getList(listName, filterKey, filterValue)

        .subscribe((data) => resolve(data));
    });
  }

  /* Get data for the database table globalData */
  getChartData(): Promise<any> {
    return this._getData('globalData').then((datas) => this._sortChartDatas(datas));
  }

  /*
   * Calls firebase to get the contry and regions translations and stores them in memory as two
   * objects with the countries and regions ISO codes as keys and translated names as values.
   */
  getCountryAndRegionNames(): Promise<any> {
    if (!this.countryNames) {
      let promises = [];
      promises.push(
        this._getData('countryNames').then((countries) => {
          let names = {};
          countries.forEach((c) => {
            names[c.Code] = {
              en: c['English'],
              fr: c['French'],
              gina: c['GINA']
            };
          });
          this.countryNames = names;
          return Promise.resolve();
        })
      );
      promises.push(
        this._getData('regionNames').then((regions) => {
          let names = {};
          regions.forEach((c) => {
            names[c.Code] = {
              en: c['English'],
              fr: c['French']
            };
          });
          this.regionNames = names;
          return Promise.resolve();
        })
      );
      return Promise.all(promises);
    } else {
      return Promise.resolve();
    }
  }

  /*
   * Translate given list of country names (for the search dropdowns)
   * Returns a promise with an array of objects with the code and the name for each country
   */
  getCountryNames(countries: Array<string>, lang: string): Promise<Array<any>> {
    return this.getCountryAndRegionNames().then(() => {
      return countries
        .map((c) => {
          if (c === 'GLOBAL') {
            return {
              code: c,
              name: 'Global'
            };
          } else {
            if (this.countryNames[c]) {
              return {
                code: c,
                name: this.countryNames[c][lang]
              };
            }
          }
        })
        .filter((c) => c);
    });
  }

  /*
   * Get the country name fron the
   */
  getCountryNamefromIso(iso: string, lang: string): Promise<string> {
    return this.getCountryAndRegionNames().then(() => {
      return this.countryNames[iso][lang];
    });
  }

  /*
   * Get the country name fron the
   */
  getCountryGinaUrlFromIso(iso: string): Promise<string> {
    return this.getCountryAndRegionNames().then(() => {
      return this.countryNames[iso]['gina'] && this.countryNames[iso]['gina'] !== '' ? this.countryNames[iso]['gina'] : null;
    });
  }

  /*
   * Gets the databar data for a specific country in a specific lang
   */
  // tslint:disable-next-line: member-ordering
  getCountryDatabarData(country: string, lang: string): Promise<Array<any>> {
    const langToGet = lang === 'fr' ? 'French Title:' : 'English Title:';
    return this.getDataFields()
      .then((dataFields) => {
        return Object.keys(dataFields)
          .map((k) => dataFields[k])
          .filter((x) => x['Field'] && x['Databar'] && parseInt(x['Databar']) > 0)
          .sort((a, b) => {
            let databarA = parseInt(a['Databar']);
            let databarB = parseInt(b['Databar']);
            if (databarA < databarB) {
              return -1;
            }
            if (databarA > databarB) {
              return 1;
            }
            return 0;
          });
      })
      .then((databarFields) => {
        return this._getData('countries', 'ISO code', country).then((countryData) => {
          //console.log('countryData', countryData);
          return databarFields.map((x) => {
            let value = this._getRecentValue(x['Field'], countryData);
            value['name'] = x[langToGet];
            value['dataType'] = x['Data Type'];
            value['source'] = x['Source:'];
            return value;
          });
        });
      });
  }

  /* Return an object of three arrays for the chart */
  private _sortChartDatas(datas: Array<any>): Object {
    let sortedDatas = {
      needs: [],
      treated: [],
      years: []
    };
    datas.map((data) => {
      sortedDatas.needs.push(data['Estimated_Global_Prevalent_SAM'] ? data['Estimated_Global_Prevalent_SAM'] : null);
      sortedDatas.treated.push(data['SAM_Children_accessing_treatment'] ? data['SAM_Children_accessing_treatment'] : null);
      if (data['Year']) {
        sortedDatas.years.push(Math.round(data['Year']));
      }
    });
    return sortedDatas;
  }

  /*Pruebas De carla*/

  // tslint:disable-next-line: member-ordering
  public getSubNationalData(): Promise<any> {
    return this._getData('subCountries')
      .then((response) => {
        return response;
      })
      .then((response) => {
        return this.filtermyService(response).then((filteredCountries) => {
          return filteredCountries;
        });
      });
  }

  public getNationalData(Lnag): Promise<any> {

    return this._getData('countries')
      .then((natData) => {
        natData.forEach((v) => {
          _.mapObject(v, function (val, key) {
            if (_.isEmpty(val)) {
              delete v[key];
            } else {
              return val;
            }

          });
        });
        return natData;
        // this.unifyData(natData,Lnag);
      }).then((natData) => {
        return this.unifyData(natData, Lnag).then((response) => {
          return response;
        })
      })

  }


  public getDataCharts(lang): Promise<any> {
    const countrlang = lang === 'fr' ? 'Country_FR' : 'Country_EN';
    const titleToGet = lang === 'fr' ? 'FR_Measure' : 'EN_Measure';
    const regionToGet = lang === 'fr' ? 'Region_FR' : 'Region_En';
    return this._getData('charts')
      .then((chartData) => {
        chartData.forEach((c) => {
          c.y = c.y.slice(0, 4)
          c.indicators = c.indicators.map((ind) => {
            return {
              title: ind[titleToGet],
              countries: ind['countries'],
              type: ind['type']
            }
          })
          c.indicators.forEach(inc => {
            inc.countries = inc.countries.map((cou) => {
              return {
                code: cou['Code'],
                country: cou[countrlang],
                region: cou[regionToGet]
              }
            })

          });

        })

        return chartData
      })

  }

  public getChartsYear(lang): Promise<any> {

    return this.getDataCharts(lang)
      .then((response) => {

        let years = [];
        response.forEach((value) => {
          if (_.isUndefined(_.findWhere(years, { year: value.year }))) {
            years.push({
              year: value.y,
              id: this.createUID()
            })
          }

        })


        return years.sort(function (a, b) {

          if (a.year > b.year) {
            return 1;
          }
          if (a.year < b.year) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });;
      })

  }




  private unifyData(natData, Lnag): Promise<any> {


    let allYears = new Array();
    return this._getData('globalData')
      .then((response) => {

        response.forEach((v) => {
          allYears[v.Year.slice(0, 4)] = {
            ind: []
          }
        })

        natData.forEach((val, key) => {
          for (const property in val) {
            if (property !== 'year') {
              allYears[val.year.slice(0, 4)].ind.push(property)
            }

          }

        })
        allYears.forEach((value) => {
          value.ind = _.uniq(value.ind);
        })

        this.getDataFields()
          .then((response) => {
            const langToGet = Lnag === 'fr' ? 'Subcategory_FR' : 'Subcategory';
            const titleToGet = Lnag === 'fr' ? 'French Title:' : 'English Title:';
            const ind = _.values(response)
              .map((k) => {
                return {
                  subCategory: k[langToGet],
                  title: k[titleToGet],
                  type: k['Type'].toLowerCase(),
                  dataType: k['Data Type'],
                  field: k['Field'],
                  id: this.createUID()
                }
              })
            allYears.forEach((value) => {
              if (value.ind.length !== 0) {
                value.ind.forEach((val, index) => {
                  let myValue = _.where(ind, { field: val })
                  value.ind[index] = myValue[0]
                })
              }

            })

          })
        return allYears
      })




  }



  getAllIndicatorCharts(Lnag): Promise<any> {
    return this.getDataFields()
      .then((response) => {
        const langToGet = Lnag === 'fr' ? 'Subcategory_FR' : 'Subcategory';
        const titleToGet = Lnag === 'fr' ? 'French Title:' : 'English Title:';
        const ind = _.values(response)
          .map((k) => {
            return {
              subCategory: k[langToGet],
              title: k[titleToGet],
              type: k['Type'].toLowerCase(),
              dataType: k['Data Type'],
              field: k['Field'],
              id: this.createUID()
            }
          })
        return ind;
      })
  }


  public getCoutryPerYear(): Promise<any> {
    return this._getData('globalData')
      .then((response) => {
        let yearCountry = new Array();
        response.forEach((v) => {
          yearCountry[v.Year.slice(0, 4)] = [];
        })

        this._getData('countries')
          .then((natData) => {
            natData.forEach((v) => {
              _.mapObject(v, function (val, key) {
                if (_.isEmpty(val)) {
                  delete v[key];
                } else {
                  return val;
                }

              });
            });

            let newData = natData.map((val) => {
              return {
                iso: val['ISO code'],
                countryName: val['country'],
                year: val['year'].slice(0, 4),
                ind: _.allKeys(val),
                isSelected: false
              }
            })


            newData.forEach((val) => {
              yearCountry[val.year].push(val);

            })


          })
        return yearCountry;
      })
  }


  public getIndicatorsXY(Lnag): Promise<any> {
    return this._getData('IndicatorsXY')
      .then((response) => {
        const langX = Lnag === 'fr' ? 'XFR' : 'XEN';
        const langY = Lnag === 'fr' ? 'YFR' : 'YEN';

        var newArray = response.splice(0, 1);
        return response.map((value) => {
          return {
            value: value['X'],
            nameX: value[langX],
            nameY: value[langY],

          }

        })
      })
  }

  // tslint:disable-next-line: member-ordering
  private filtermyService(newData): Promise<any> {
    let countries = [];
    return this.getGlobalCountryData().then((response) => {
      newData.forEach((subData) => {
        response.forEach((country) => {
          if (subData['ISO_code'] === country['ISO code'])
            countries.push({
              id: Math.floor(Math.random() * 10000000000000 + 1),
              name: country.country,
              countryIsoCode: country['ISO code'],
              SFP_OTP: subData['SFP_OTP'],
              year: subData['Year'].substring(0, 4)
            });
        });
      });
      // countries = this._noRepeatCountry(countries);

      return countries;
    });
  }

  getGlobalYear(): Promise<any> {
    return this._getData('globalData')
      .then((response) => {
        let years = [];
        response.forEach((value) => {
          years.push({
            year: value.Year.slice(0, 4),
            id: this.createUID()
          })
        })

        return years;
      })

  }

  getYIndicators(lang, even): Promise<any> {
    return this.getIndicatorsXY(lang).then((response) => {

      return _.where(response, { nameX: even.nameX }).filter(value => value.nameY !== even.nameX)
    })


  }
  getNationallYear(): Promise<any> {

    return this._getData('countries')
      .then((response) => {
        let years = [];
       // console.log('service year',_.findWhere(response, { year: '2019.00'}));
        response.forEach((value) => {
          if (_.isUndefined(_.findWhere(years, { year: value.year.slice(0, 4) }))) {
            years.push({
              year: value.year.slice(0, 4),
              id: this.createUID()
            })
          }

        })

        return years.sort(function (a, b) {

          if (a.year > b.year) {
            return 1;
          }
          if (a.year < b.year) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      })

  }

  countriestByRegion(list: any, regions: any): Observable<any> {
    let newCon = [];

    regions.forEach((val) => {
      list.forEach((con) => {
        if (con.name === val) {
          newCon.push(con.countries)
        }
      })
    })
    newCon = _.flatten(newCon)
    newCon.sort(function (a, b) {

      if (a.name > b.name) {

        return 1;
      }
      if (a.name < b.name) {

        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return of(newCon).pipe(delay(500));



  }


  getCountiesByYear(list: any, regions: any): Observable<any> {
    let newList = [];
  //  console.log(list);
    list.sort(function (a, b) {

      if (a.country > b.country) {

        return 1;
      }
      if (a.country < b.country) {

        return -1;
      }
      // a must be equal to b
      return 0;
    });
    if (regions[0] === 'all' || regions.length === 0) {
      newList = list;
    } else {
      regions.forEach((val) => {
        list.forEach((country) => {
          if (country.region === val) {
            newList.push(country);
          }
        })
      })
    }
    return of(newList).pipe(delay(500));


  }



  public getTypeProgram(): Promise<any> {
    return this.getSubNationalData()
      .then((response) => {
        return response;
      })
      .then((response) => {
        return this._noRepeatTypeofProgram(response);
      });
  }

  public getsubNationalYear(): Promise<any> {
    return this.getSubNationalData()
      .then((response) => {
        return response;
      })
      .then((response) => {
        return this._noRepeatYear(response);
      });
  }

  public subNationalCountry(countriesList) {
    return this._noRepeatCountry(countriesList).sort(function (a, b) {

      if (a.name > b.name) {

        return 1;
      }
      if (a.name < b.name) {

        return -1;
      }
      // a must be equal to b
      return 0;
    });;
  }

  private createUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  private _noRepeatYear(data) {
    return _.uniq(data, function (item, key, year) {
      return item.year;
    });
  }

  private _noRepeatTypeofProgram(types) {
    return _.uniq(types, function (item, key, SFP_OTP) {
      if (item.SFP_OTP !== '   ') {
        return item.SFP_OTP.toLowerCase();
      }
    });
  }

  /* Filters the received data by a year */
  private _filterByYear(data: Array<any>, year: number): Array<any> {
    return data.filter((item) => parseFloat(item.year) === year);
  }

  /*
   * Aggregates the received data into a object with sums or averages of
   * every numeric field present in each object of the data
   */
  private _aggregateData(data: Array<any>): Object {
    if (data.length) {
      let aggData = {};
      let fieldsToAgg = this._getKeysOfNumberFields(data[0]);
      fieldsToAgg.forEach((field) => {
        aggData[field] = this._aggregateField(field, data, true);
      });
      return aggData;
    } else {
      return {};
    }
  }

  /*
   * DEPRECATED - NOT IN USE
   * Aggregates the received data into a object with the most
   * recent datas and the corresponding year
   */
  private _sortRecentData(data: Array<any>): Object {
    if (data.length) {
      let recentData = {};
      let fieldsToAgg = this._getKeysOfNumberFields(data[0]);
      fieldsToAgg.forEach((field) => {
        recentData[field] = this._getRecentValue(field, data);
      });
      return recentData;
    } else {
      return {};
    }
  }

  /*
   * Return an object that contains the most recent value of a field
   * and the year of the data
   */
  private _getRecentValue(field: string, data: Array<any>): any {
    let res = {};
    data
      .filter((x) => x['year'] && !isNaN(parseInt(x['year'])))
      .forEach((entry) => {
        let actualYear = parseInt(entry['year']);
        let actualValue = entry[field];
        if (res['year']) {
          if (actualValue && actualValue.trim() !== '') {
            if (res['value'].trim() !== '') {
              if (res['year'] < actualYear) {
                res['year'] = actualYear;
                res['value'] = actualValue;
              }
            } else {
              res['year'] = actualYear;
              res['value'] = actualValue;
            }
          }
        } else {
          res['year'] = actualYear;
          res['value'] = actualValue;
        }
        /*
        res['year'] ?
          res['year'] < entry['year'] ?
            (res['year'] = Math.round(entry['year']), res['value'] = entry[field])
            : ''
        :  (res['year'] = Math.round(entry['year']), res['value'] = entry[field])
        */
      });
    return res;
  }

  /* Get the keys in an object corresponding to numeric fields */
  private _getKeysOfNumberFields(entry: any): Array<string> {
    let keys = [];
    Object.keys(entry).forEach((key) => {
      if (key !== 'Year' && !isNaN(parseFloat(entry[key]))) {
        keys.push(key);
      }
    });
    return keys;
  }

  /*
   * Aggregate all the values of a field in an array of objects
   * specifying if the aggregation is an average or a sum
   * and return a string with the lower year and the higher year
   */
  private _aggregateField(field: string, data: Array<any>, average: boolean): Object {
    let res = {};
    let sum = 0;
    let count = 0;
    let minYear;
    let maxYear;
    data.forEach((entry) => {
      minYear = minYear ? (entry.year < minYear ? entry.year : minYear) : entry.year;
      maxYear = maxYear ? (entry.year > maxYear ? entry.year : maxYear) : entry.year;
      let number = parseFloat(entry[field]);
      if (!isNaN(number)) {
        sum += number;
        count++;
      }
    });
    res['value'] = average ? (count > 0 ? (sum / count).toFixed(2) : '0') : sum.toFixed(2);
    res['year'] = minYear && maxYear ? Math.round(minYear) + '-' + Math.round(maxYear) : '';
    return res;
  }

  /* Returns the country name and the country region as an object */
  private _getNameAndRegions(data) {
    return {
      countryIsoCode: data['ISO code'],
      regionUnicefCode: data['UNICEF Region 1']
    };
  }

  /* Complete the array of countries with their translations */
  private _getNameTranslations(countries: Array<any>, lang: string): Array<any> {
    return countries
      .filter((c) => c && c['countryIsoCode'] !== '' && c['regionUnicefCode'] !== '')
      .map((c) => {
        c.name = this.countryNames[c.countryIsoCode][lang];
        c.regionName = this.regionNames[c.regionUnicefCode][lang];
        return c;
      });
  }

  /*Returns an Array where countries appear only once */
  private _noRepeatCountry(countries): Array<any> {
    let singleCountries = [];
    countries.forEach((country) => {
      let index = singleCountries
        .map((c) => {
          return c.countryIsoCode;
        })
        .indexOf(country.countryIsoCode);
      if (index === -1) {
        singleCountries.push(country);
      }
    });
    return singleCountries;
  }

  /* Returns an Array of regions,
   *  each region contains its name and an Array with its coutries
   */
  private _groupByRegion(countries): Array<any> {
    let sortedCountries = [];
    countries.forEach((country) => {
      let index = sortedCountries
        .map((region) => {
          return region.code;
        })
        .indexOf(country.regionUnicefCode);
      if (index === -1) {
        sortedCountries.push({
          code: country.regionUnicefCode,
          name: country.regionName,
          countries: [{ code: country.countryIsoCode, name: country.name }]
        });
      } else {
        sortedCountries[index].countries.push({ code: country.countryIsoCode, name: country.name });
      }
    });
    return sortedCountries;
  }
}
