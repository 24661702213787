import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentfulService } from '../service/contentful.service';
import { HeaderMenuService } from '../service/header-menu.service';
import { I18NService } from '../service/i18n.service';
import { DatasourceService } from '../service/datasource.service';
import '../../styles/components/header.scss';
import * as $ from 'jquery';

@Component({
  templateUrl: './header.component.html',
  selector: 'header',
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit {

  headerMenu;
  headerItems = [];
  topBarItems = [];
  activeLang: string;

  constructor(
    private contentfulService: ContentfulService,
    private i18nService: I18NService,
    private headerMenuService: HeaderMenuService,
    private datasourceService: DatasourceService
  ) {
  }

  ngOnInit() {
    this.headerMenuService.get()
      .then((headerMenu) => {
        this.activeLang = this.i18nService.getActiveLang();
        this.headerMenu = headerMenu;
        this.headerItems = headerMenu.headerItems;
        this.topBarItems = headerMenu.topBarItems;
      });
  }

  setLang(lang: string) {
    this.i18nService.setLang(lang);
  }

  closeNav() {
    $('.navbar-collapse').removeClass('show');
  }

}
