import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EventsService } from '../service/events.service';
import { MetaService } from 'app/service/meta.service';
import { ScreenSizeService } from '../service/screen-size.service';
import { AppConfigService } from 'app/core/services/app-config.service';
import { ActivatedRoute } from '@angular/router';
import { I18NService } from '../service/i18n.service';
import '../../styles/components/events.scss';
import * as moment from 'moment';

@Component({
  templateUrl: './events.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'events'
})

export class EventsComponent implements OnInit {

    events: Array<any> = undefined;
    blogs: Array<any> = undefined;
    upcomingEvents: Array<any> = undefined;
    previousEvents: Array<any> = undefined;
    resultEvents: Array<any> = undefined;
    ongoingEvents: Array<any> = undefined;
    query: any = {};
    country: string;
    timeOptions = [
      { name: "events.search.date.options.week", unit: "week", amount: "1" },
      { name: "events.search.date.options.month", unit: "month", amount: "1" },
      { name: "events.search.date.options.three-months", unit: "month", amount: "3" },
      { name: "events.search.date.options.six-months", unit: "month", amount: "6" },
      { name: "events.search.date.options.year", unit: "year", amount: "1" }
    ];
    countries: Array<string> = [];
    categories: Array<string> = [];
    //countries = ["Kenya", "Angola", "Switzerland", "Antarctica", "India", "Afghanistan"];
    //eventCategories = ["Conference", "Training", "Workshop", "Event"];
    isLoading: boolean = false;
    time: any;
    isOngoing: boolean = false;
    isPrevous: boolean = false;
    noMoreResults: boolean = false;
    noMorePrevious: boolean = false;
    noMoreEvents: boolean = false;
    noMoreOngoing: boolean = false;
    noMoreBlogs: boolean = false;
    colWidth: number;
    sizes: any;
    pageSize: number;

    constructor(
      private route: ActivatedRoute,
      private eventsService: EventsService,
      private metaService: MetaService,
      private screenSizeService: ScreenSizeService,
      private titleService: Title,
      private appConfigService: AppConfigService,
      private i18NService: I18NService
    ) {
      this.i18NService.getSingleTranslation('share.soam.text')
        .then((translation) => {
          this.i18NService.getSingleTranslation('share.soam.events')
            .then((translation2) => {
              this.titleService.setTitle(translation2 + translation);
              this.metaService.resetMetas();
              this.metaService.addMetas(
                translation2 + translation,
                translation2 + translation,
              );
            });
        });
      this.sizes = this.appConfigService.appConfig['sizes'];
      this.colWidth = this.screenSizeService.getThumbnailColWidth();
      this.pageSize = appConfigService.appConfig['pagination']['eventsMaxPageSize'];
      this.eventsService.getCountries()
        .then(countries => { this.countries = countries; });
      this.eventsService.getCategories()
        .then(categories => { this.categories = categories; });
    }

    ngOnInit() {
      this.route.params.subscribe(params => {
        let country = params['country'];
        this.country = country;
        this.query.country = country;

       
        this.eventsService.getUpcomingEvents(country)
          .then(events => {
            if (events.length < this.pageSize) {
              this.noMoreEvents = true;
            }
            this.upcomingEvents = events;
            this.events = events.slice(0, this.pageSize);
          })
          .catch((err) => {
            console.error('error', err);
          });
        
        this.eventsService.getOngoingEvents(country)
          .then(events => {
            if (events.length < this.pageSize) {
              this.noMoreOngoing = true;
            }
            this.ongoingEvents = events;
          })
          .catch((err) => {
            console.error('error', err);
          });

        this.eventsService.getPreviousEvents(country)
          .then((events) => {
            if (events.length < this.pageSize) {
              this.noMorePrevious = true;
            }
            this.previousEvents = events;
          })
          .catch((err) => {
              console.error('error', err)
          });
          this.eventsService.getNewsEvents(country)
          .then((blogs) => {
           
            if (blogs.length < this.pageSize) {
              this.noMorePrevious = true;
            }
            this.blogs=blogs.sort(this.comparePDate);
          })
          .catch((err) => {
              console.error('error', err)
          });
      });
    }

    searchEvents() {
      this.noMoreResults = false;
      
      if (this.time) {
        if (this.time === 'ongoing') {
          this.query['ongoing'] = true;
          this.query['date'] = this.getDate({ amount: 0, unit: 'day' });
        } else if (this.time === 'previous') {
          this.query['previous'] = true;
          this.query['date'] = this.getDate({ amount: -1, unit: 'day' });
        } else {
          this.query['ongoing'] = false;
          this.query['previous'] = false;
          this.query['date'] = this.getDate(this.time);
        }
      } else {
        this.query['date'] = null;
      }

      this.isLoading = true;
      this.eventsService.searchEvents(this.query)
        .then((events) => {
          if (events.length < this.pageSize) {
            this.noMoreResults = true;
          }
          this.isLoading = false;
          this.resultEvents = events;
        })
        .catch((err) => {
          console.error('error', err)
        });
    }

    loadMoreResultEvents() {
      if (this.time) {
        if (this.time === 'ongoing') {
          this.query['ongoing'] = true;
          this.query['date'] = this.getDate({ amount: 0, unit: 'day' });
        } else if (this.time === 'previous') {
          this.query['previous'] = true;
          this.query['date'] = this.getDate({ amount: -1, unit: 'day' });
        } else {
          this.query['date'] = this.getDate(this.time);
        }
      } else {
        this.query['date'] = null;
      }
      this.isLoading = true;
      let skip = this.resultEvents ? this.resultEvents.length : 0;
      this.eventsService.searchEvents(this.query, skip)
        .then((events) => {
          if (events.length < this.pageSize) {
            this.noMoreResults = true;
          }
          this.isLoading = false;
          events.forEach((event) => {
            this.resultEvents.push(event);
          });
        })
        .catch((err) => {
          console.error('error', err);
        });
    }

    loadMoreUpcomingEvents() {
      let moreEvents = this.upcomingEvents.slice(this.events.length, this.events.length + this.pageSize);
      if (moreEvents.length < this.pageSize) {
        this.noMoreEvents = true;
      }
      this.events = this.events.concat(moreEvents);
    }
 

   loadMoreOngoingEvents() {
      this.eventsService.getOngoingEvents(this.country, this.ongoingEvents.length)
        .then((events) => {
          if (events.length < this.pageSize) {
            this.noMoreOngoing = true;
          }
          this.ongoingEvents = this.ongoingEvents.concat(events);
        });
    }

    loadMorePreviousEvents() {
      this.eventsService.getPreviousEvents(this.country, this.previousEvents.length)
        .then((events) => {
          if (events.length < this.pageSize) {
            this.noMorePrevious = true;
          }
          this.previousEvents = this.previousEvents.concat(events);
        });
    }
    loadMoresBlogs() {
      this.eventsService.getNewsEvents(this.country, this.blogs.length)
        .then((blogs) => {
          if (blogs.length < this.pageSize) {
            this.noMoreBlogs = true;
          }
          this.blogs = this.blogs.concat(blogs);
        });
    }


    getDate(time) {
      return moment(new Date()).add(time.amount, time.unit).format();
    }

    isQueryEmpty(){
      // For leaving out country filter
      return Object.keys(this.query).length <= 1
    }

    clearSearch(){
      this.query = {};
      this.query.country = this.country;
      this.resultEvents = undefined;
    }
   
    comparePDate(a, b) {
      const publishedDateA = a.fields.publishedDate
      const publishedDateb = b.fields.publishedDate    
      let comparison = 0;
      if (publishedDateb > publishedDateA) {
        comparison = 1;
      } else if (publishedDateb < publishedDateA) {
        comparison = -1;
      }
      return comparison;
    }
}
