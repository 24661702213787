import { Component, Input, Renderer, HostListener } from '@angular/core';
import { HeaderMenuService } from 'app/service/header-menu.service';

@Component({
    templateUrl: './modal.component.html',
    selector: 'modal'
})

export class ModalComponent {

  visible = false;
  visibleAnimate = false;
  logoText: any;
  orgText: String = 'acutemalnutrition.org';
  questionsText: String = 'acutemalnutrition.org';
  emailText: String = 'acutemalnutrition.org';

  @Input() isDesktop : boolean = false;
  
   
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {      
    this.renderer.setElementClass(document.body, 'modal-open', false);
  }


  constructor(private renderer: Renderer, private headerMenuService: HeaderMenuService) {

  }

  show() {
    if (!this.logoText) {
      this.headerMenuService.get()
        .then((headerMenu) => {
          try {
            this.logoText = headerMenu['headerLogo']['fields']['logoText'];
            this.showModal();
          } catch (err) {
            this.logoText = '<p>&nbsp;The State of <strong>Acute Malnutrition</strong></p>';
            this.showModal();
          }
        });
    } else {
      this.showModal();
    }
  }

  showModal() {
    this.visible = true;
    this.renderer.setElementClass(document.body, 'modal-open', true);
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  hide() {
    this.visibleAnimate = false;
    this.renderer.setElementClass(document.body, 'modal-open', false);
    setTimeout(() => this.visible = false, 300);
  }

  onContainerClicked(event) {
    const isModalBackdrop = (<HTMLElement>event.target).classList.contains('modal')
    const isModalCloseButton = (<HTMLElement>event.target).classList.contains('close')
    if (isModalBackdrop || isModalCloseButton) {
      this.hide();
    }
  }

}
