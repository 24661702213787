import { Injectable } from '@angular/core'
import { ContentfulService } from 'app/service/contentful.service';

@Injectable()
export class CountryDataThumbnailsService {

  constructor(private contentfulService: ContentfulService) {
  }

  get() {
    // Return only the first element because there arent more
    return this.contentfulService.getEntries('countryDataThumbnails')
      .then((thumbnails) => { 
        if (thumbnails && thumbnails.length > 0) {
          if (thumbnails[0].fields.mapThumbnail && thumbnails[0].fields.chartsThumbnail) {
            let mapThumbnail = thumbnails[0].fields.mapThumbnail.fields
            let chartsThumbnail = thumbnails[0].fields.chartsThumbnail.fields
            return { chartsThumbnail, mapThumbnail };
          }
        }
        throw new Error('Did not have necessary data');
      });
  }

}
