//import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
//import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { PartnerLogoSliderService } from 'app/service/partner-logo-slider.service';
//import * as $ from 'jquery';
import '../../../../styles/components/partner-logo-slider.scss';

@Component({
  templateUrl: './partner-logo-owl-slider.component.html',
  selector: 'partner-logo-slider'
})

export class PartnerLogoSliderComponent implements OnInit {

  logos = [];
  mobileLogos = [];
  constructor(
    //@Inject(PLATFORM_ID) private platformId: Object,
    private partnerLogoSliderService: PartnerLogoSliderService
  ) { }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<a class="carousel-control-prev" ><span class="fa fa-caret-left fa-w-6" aria-hidden="true"></span></a>', 
      '<a class="carousel-control-next"><span class="fa fa-caret-right fa-w-6" aria-hidden="true"></span></a>'],
    responsive: {
      0: {
        items: 1
      },
      250: {
        items: 1
      },
      540: {
        items: 2
      },
      790: {
        items: 4
      }
    },
    nav: true,
    autoplay:true,
    autoplayTimeout: 5000,
    smartSpeed: 700,
    autoplayHoverPause:true
  }

  ngOnInit() {
    /*
    if (isPlatformBrowser(this.platformId)) {
      // Run this only on browser
      this._initSlider();
    }
    */
    this.partnerLogoSliderService.get()
      .then((data) => {
        const itemsPerSlide = 4;
        this.mobileLogos = data;
        
        let slides = data.length;

        let index = 0;
        while (index < slides) {
          const until = index + itemsPerSlide;
          if (until < slides)
            this.logos.push(data.slice(index, until));
          else {
            const offset = until - slides;
            const slide = data.slice(index).concat(data.slice(0, offset));
            this.logos.push(slide);
          }
          index += 1;
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  _initSlider() {
    /*
    $('#logo-slider-desktop').carousel({
      interval: 60000
    });
    $('#logo-slider-mobile').carousel({
      interval: 60000
    });
    */
    /*
    $('#logo-slider-desktop').off('slide.bs.carousel');
    $('#logo-slider-desktop').on('slide.bs.carousel', (e) => {
      console.log('sliding');
      var $e = $(e.relatedTarget);
      var idx = $e.index();
      var itemsPerSlide = 4;
      var totalItems = $('#logo-slider-desktop .carousel-item').length;
      if (idx >= totalItems-(itemsPerSlide-1)) {
        var it = itemsPerSlide - (totalItems - idx);
        for (var i=0; i<it; i++) {
          // append slides to end
          if (e.direction=='left') {
            $('#logo-slider-desktop .carousel-item').eq(i).appendTo('#logo-slider-desktop .carousel-inner');
          } else {
            $('#logo-slider-desktop .carousel-item').eq(0).appendTo('#logo-slider-desktop .carousel-inner');
          }
        }
      }
    });*/
  }

}
