import { Component, ViewEncapsulation, Input } from '@angular/core';
import '../../../../styles/components/home-slider.scss';

@Component({
  templateUrl: './home-slider.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'home-slider'
})

export class HomeSliderComponent {

  @Input() slides : Array<any>;
  height = 410;

  constructor() {}

  /*
  ngOnInit() {
    this.homeSliderService.get()
      .then((data) => {
        this.slides = data;
      })
      .catch((error) => {
        console.log('error', error);
      });
  }
  */

}
