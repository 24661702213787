import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from 'app/core/services/app-config.service';
import { ScreenSizeService } from 'app/service/screen-size.service';
import { I18NService } from '../service/i18n.service';
import {DatasourceService} from '../service/datasource.service';
import * as _ from 'underscore';
@Component({
  templateUrl: './charts.component.html',
  selector: 'charts'
})

export class ChartsComponent implements OnInit {

  @Input() country: string;

  allCharts: Array<any> = [];
  charts: Array<any> = [];
  loading: boolean = false;
  
  activeLang: string;
  selectedChart: any;

  chart:any;

  showRegion: Boolean = false;
  type:any;
  indicators: Array<any> = [];
  indPyear: any;
  nameChart: any;
  number: any;
  constructor(
    private appConfigService: AppConfigService,
    private screenSizeService: ScreenSizeService,
    private i18nService: I18NService,
    private datasourceService: DatasourceService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.activeLang = this.i18nService.getActiveLang();

    this.allCharts = this.appConfigService.appConfig['tableauConfig']['chartsSection-'+this.activeLang];
    if (!this.country) {
      this.charts = this.allCharts.filter(c => c && c['global'])
    } else {
      this.charts = this.allCharts.filter(c => c && !c['global'])
    }
    
     this.charts.forEach((value)=>{
      value.id =Math.floor(Math.random() * 10 + 1);
      value.graph= value.title.slice(0,8)
    })

    this.chart=this.charts[0];
    this.nameChart=1,
    this.selectedChart=this.charts[0].id
    this.loading = false;

    setTimeout(() => {
      this.loading = false;
      
    }, 1000);
  }

  onChange($event){
    this.chart=$event;
    switch(this.chart.graph) {
      case 'GRAPH 1:':
        this.nameChart=1
        this.type='numeric';
        this.number=1;
      break;
      case  'GRAPH 2:':        
        this.nameChart=1;
        this.number=2;
        this.type='percentage';
      break;
      case  'GRAPH 3:':
        this.nameChart=1;
        this.number=3;
        this.type='numeric';
      break;
      case  'GRAPH 4:':
        this.nameChart=1;
        this.number=4;
        this.type='percentage';
      break;
      case  'GRAPH 5:':
        this.nameChart=5
        this.type='5'
      break;
      case  'GRAPH 6:':
        this.nameChart=5
        this.type='6'
      break;
      case  'GRAPH 7:':
        this.nameChart=7
        this.type='numeric';
      break;
      case  'GRAPH 8:':
        this.nameChart=7
        this.type='percentage';
      break;
      case  'GRAPH 9:':
        this.nameChart=9
        this.type='numeric';
      break;
      case  'GRAPH 10':
        this.nameChart=9
        this.type='percentage';
      break;      
      default:
    }
  }
}