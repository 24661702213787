import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormWizardModule } from 'angular2-wizard';
import { SharedModule } from 'app/shared/shared.module';
import { MapModule } from 'app/map/map.module';
import { ChartsModule } from 'app/charts/charts.module';
import { CountryDataComponent } from 'app/country-data/country-data.component';

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    CountryDataComponent
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    FormWizardModule,
    MapModule,
    ChartsModule,
    SharedModule
  ],

  exports: [
    CountryDataComponent
  ]
})

export class CountryDataModule {

}
