import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FooterService } from '../service/footer.service';
import '../../styles/components/footer.scss';

@Component({
  templateUrl: './footer.component.html',
  selector: 'footer',
  encapsulation: ViewEncapsulation.None
})

export class FooterComponent implements OnInit {

  footer: any = {};
  logoContentful: string;

  constructor(private footerService: FooterService) {
  }

  ngOnInit() {
    this.footerService.get()
      .then((footer) => {
        this.logoContentful = '';
        this.footer = footer;
        if (footer.logoContentful.hasOwnProperty('fields')) {
          this.logoContentful = footer.logoContentful.fields.file.url;
        }
      });
  }
}
