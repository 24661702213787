import { Injectable } from '@angular/core'
import { ContentfulService } from 'app/service/contentful.service';

@Injectable()
export class HomepageService {

  constructor(private contentfulService: ContentfulService) {
  }

  get() {
    // Return only the first element because there arent more
    return this.contentfulService.getEntries('homepage')
      .then((home) => home[0].fields);
  }

}
