import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from 'app/shared/shared.module';
import { HeaderComponent } from 'app/header/header.component';

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    HeaderComponent
  ],

  providers: [],

  // Modules
  imports: [
    BrowserModule,
    CommonModule,
    SharedModule,
    RouterModule
  ],

  exports: [
    HeaderComponent
  ]
})

export class HeaderModule {

}
