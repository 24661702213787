import { Component, OnInit, Input,  OnChanges ,SimpleChanges  } from '@angular/core';
import { AppConfigService } from 'app/core/services/app-config.service';
import { ScreenSizeService } from 'app/service/screen-size.service';
import { I18NService } from '../../../service/i18n.service';
import {DatasourceService} from '../../../service/datasource.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-graph9',
  templateUrl: './graph9.component.html',
})
export class Graph9Component implements OnInit,OnChanges {

  @Input() country: string;
  @Input() chart: any;
  @Input() type:any;
  baseUrl: string;
  hostUrl: string;
  tableauImg: string;
  tableauName: string;
  tableauScript: any = null;
  tableauDiv: any = null;subcategories: Array<any> = [];
 
  charts: Array<any> = [];
  loading: boolean = false;
  showTableau: boolean = false;
  recentlyOpen: boolean = true;
  activeLang: string;
  selectIndicator: any;
  years: any;
  
  indicators: Array<any> = [];
  indPyear: any;
  countPyear: Array<any> = [];
  countries: Array<any> = [];
  listCountry: any;

  constructor(
    private appConfigService: AppConfigService,
    private screenSizeService: ScreenSizeService,
    private i18nService: I18NService,
    private datasourceService: DatasourceService
  ) { }

  ngOnChanges(changes: SimpleChanges){
    if(_.isUndefined(this.type)){
        this.type='numeric'
    }
    this.initialization();
  
  }

  ngOnInit() {}

  initialization(){
    this.loading = true;
    this.activeLang = this.i18nService.getActiveLang();
    this.showTableau = false;
    this.recentlyOpen = true;
    this.baseUrl = this.appConfigService.appConfig['tableauConfig']['baseUrl'];
    let scriptElement = document.createElement('script');
    scriptElement.src = this.appConfigService.appConfig['tableauConfig']['src'];
    this.tableauScript = scriptElement;
    this.tableauDiv = document.getElementById('tableau10');

  this.selectIndicator=null;
  this.getAllIndicators();
    this.callTableau();
    setTimeout(() => {
      this.loading = false;      
    }, 1000);
  }
  callTableau() {
  let name = this.chart.name;
    this.hostUrl = this.appConfigService.appConfig['tableauConfig']['hostUrl'];
    this.tableauName = name;
    let iframeElement = this.tableauDiv.getElementsByTagName('iframe')[0];
    if (!iframeElement ) {
      let vizElement = this.tableauDiv.getElementsByTagName('object')[0];
      if (!vizElement) {
        this.tableauDiv = document.getElementById('tableau10');
        vizElement = this.tableauDiv.getElementsByTagName('object')[0];
      }
      vizElement.style.width='100%';
      if (this.screenSizeService.isLg() || this.screenSizeService.isXl()) {
        vizElement.style.height=(400) + 'vh';
         vizElement.style.height='800px';
        vizElement.style.height=(this.tableauDiv.offsetWidth*0.75)+'px';
      } else {
        vizElement.style.height=(400)+'px';
      }
      vizElement.parentNode.insertBefore(this.tableauScript, vizElement);
      this.recentlyOpen = false;
    } else {
      let newHostUrl = this.baseUrl + this.tableauName + '?:embed=y&:showVizHome=no&:host_url=';
     
      newHostUrl += this.hostUrl + '&:embed_code_version=3&:tabs=no&:toolbar=no&:animate_transition=yes&:display_static_image=yes';
      newHostUrl += '&:display_spinner=yes&:display_overlay=yes&:display_count=yes&:linktarget=_blank';
      newHostUrl += '&Language=' + (this.activeLang === 'fr' ? 'French' : 'English');
      newHostUrl += '&:loadOrderID=0';
      if (this.country) {
        newHostUrl += '&Country=' + this.country;
      }    
      if (this.selectIndicator){
        newHostUrl += '&Indicator=' + this.selectIndicator.replace('%', '%25');
      }
      iframeElement.setAttribute('src', newHostUrl);
    }
    setTimeout(() => {
      this.showTableau = true;
    }, 3000);   
  }

  clearFilters(){
    this.indPyear=[];
    this.selectIndicator=null;
    this.countPyear=[];
  }
  getAllIndicators(){
    this.datasourceService.getAllIndicatorCharts(this.activeLang)
    .then((response)=>{
      this.indPyear = _.where(response, {dataType: this.type, type:'national'})
      this.selectIndicator= _.first(this.indPyear).title;
    })
  }


}
