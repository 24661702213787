import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { I18NService } from '../service/i18n.service';
import { MetaService } from 'app/service/meta.service';
import { EventsService } from '../service/events.service';
import { ScreenSizeService } from '../service/screen-size.service';
import { AppConfigService } from 'app/core/services/app-config.service';
import * as moment from 'moment';
import '../../styles/components/events.scss';

@Component({
    templateUrl: './event-detail.component.html',
    encapsulation: ViewEncapsulation.None,
    selector: 'event-detail'
})

export class EventDetailComponent implements OnInit {

    event : any = {};
    colWidth: number;
    sizes: any;

    constructor(
      private route: ActivatedRoute, 
      private router: Router, 
      private i18NService: I18NService,
      private eventsService: EventsService,
      private metaService: MetaService,
      private screenSizeService: ScreenSizeService,
      private titleService: Title,
      private appConfigService: AppConfigService) {
        this.sizes = this.appConfigService.appConfig['sizes']
    }

    ngOnInit() {
      this.colWidth = this._getColWidth()
      this.route.params.subscribe(params => {
        if (params['eventId']) {
          this.eventsService.getById(params['eventId'])
            .then((event) => {
              if (event) {
                const sanitizedTitle = this._sanitizeString(event.title);
                const sanitizedDescription = this._sanitizeString(event.description);
                this.i18NService.getSingleTranslation('share.soam.text')
                  .then((translation) => {
                    this.titleService.setTitle(sanitizedTitle);
                    this.metaService.resetMetas();
                    this.metaService.addMetas(
                      sanitizedTitle,
                      sanitizedDescription,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      event.photo ? 'https:' + event.photo.fields.file.url : null
                    );
                  });
                event.startDateFormatted = this._formatDate(event.startDate);
                event.endDateFormatted = this._formatDate(event.endDate);
                event.calendarLink = this._getCalendarLink(event);
                event.sanitizedTitle = sanitizedTitle;
                event.sanitizedDescription = sanitizedDescription;
                this.event = event;
              } else {
                this.router.navigate(['404'])
              }
            });
        } else {
          this.router.navigate(['404'])
        }
      });
    }

    _getCalendarLink(event: any) {
      let calendarLink = 'http://www.google.com/calendar/event?action=TEMPLATE';
      calendarLink += '&text=' + this._formatString(this._sanitizeString(event.title));
      calendarLink += '&dates=' + this._convertDate(event.startDate);
      if (event.endDate) {
        calendarLink += '/' + this._convertDate(event.endDate);
      } else {
        calendarLink += '/' + this._addDate(event.startDate);
      }
      calendarLink += '&details=' + this._formatString(this._sanitizeString(event.title));
      calendarLink += '&location=' + this._formatString(this._sanitizeString(event.location));
      return calendarLink;
    }

    _convertDate(d) {
      return moment.utc(d).format('YYYYMMDDTHHmmss') + 'Z';
    }

    _addDate(d) {
      return moment.utc(d).add('years', 5).format('YYYYMMDDTHHmmss') + 'Z';
    }

    _formatDate(dateString) {
      var d = new Date(dateString);
      return moment(dateString).format('MMMM DD, YYYY');
    }

    _sanitizeString(s) {
      return s.replace(/<(.|\n)*?>/g, '');
    }

    _formatString(s) {
      return encodeURI(s.replace(/<(.|\n)*?>/g, ''));
    }
    
    _getColWidth(){
      let sizes = this.sizes
      if(this.screenSizeService.isXl()){
          return sizes.containerWidthXl / 3
      }
      else if (this.screenSizeService.isLg()){
          return sizes.containerWidthLg*0.6
      }
      else if (this.screenSizeService.isMd()){
          return sizes.containerWidthMd*0.6
      }
      else if (this.screenSizeService.isSm()){
        return sizes.containerWidthSm*0.6
      }
      else {
          return Math.ceil(this.screenSizeService.getWidth()*0.6)
      }
  }

}
