import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ShareButtonModule} from '@ngx-share/button';
import {MarkdownModule} from 'ngx-markdown';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
//import { MetaModule } from 'ng2-meta';
//import { Ng2PageTransition } from './shared/utils/ng2-page-transition.directive';
import {AppRouterModule} from './app-router.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {HomeModule} from './home/home.module';
import {HeaderModule} from './header/header.module';
import {FooterModule} from './footer/footer.module';
import {CountryDataModule} from './country-data/country-data.module';
import {MapModule} from './map/map.module';
import {ChartsModule} from './charts/charts.module';
//import {CountriesListModule} from './shared/country-list/country-list.module';
import {EventsModule} from './events/events.module';
import {FaqModule} from './faq/faq.module';
import {ResourceLibraryModule} from './resource-library/resource-library.module';
import {CountriesModule} from './countries/countries.module';
import {NewsletterWidgetModule} from './newsletter-widget/newsletter-widget.module';
import {StaticPagesModule} from './static-pages/static-pages.module';
import {NotFoundModule} from './not-found/not-found.module';
import {KumuModule} from './kumu/kumu.module';
import {FormWizardModule} from 'angular2-wizard';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { from } from 'rxjs';
import { CountriesModalComponent } from './shared/country-list/countries-modal.component';

// For AoT compiler
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
//@ts-ignore
const FIREBASE_CONFIG = process.env.APP_CONFIG.fireBaseConfig || {};
@NgModule({
  // Components, Pipes, Directive
  declarations: [
    AppComponent,
    //Ng2PageTransition
  ],
  providers: [HttpClientModule, Title],

  // Modules
  imports: [
    BrowserModule.withServerTransition({appId: 'aahsam-web'}),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(FIREBASE_CONFIG),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CoreModule,
    HomeModule,
    HeaderModule,
    EventsModule,
    FaqModule,
    FooterModule,
    CountryDataModule,
    MapModule,
    ChartsModule,
    ResourceLibraryModule,
   // CountriesListModule,
    CountriesModule,
    NewsletterWidgetModule,
    StaticPagesModule,
    NotFoundModule,
    KumuModule,
    FormWizardModule,
    AppRouterModule,
    HttpClientModule,
    ShareButtonModule,
    MarkdownModule.forRoot(),
    NgSelectModule,
    FormsModule,
    FontAwesomeModule,
    NgbModule
    //MetaModule.forRoot()
  ],
  entryComponents:[CountriesModalComponent],

  // Main Component
  bootstrap: [AppComponent]
})
export class AppModule {}
