import {Component, OnInit, Input} from '@angular/core';
import {I18NService} from 'app/service/i18n.service';
import {ScreenSizeService} from 'app/service/screen-size.service';
import {CountryDataThumbnailsService} from 'app/service/country-data-thumbnails.service';

@Component({
  templateUrl: './country-data.component.html',
  selector: 'country-data'
})

export class CountryDataComponent implements OnInit {
  @Input() country: string;
  @Input() countryName: string;
  @Input() countryGinaUrl: string;
  isDesktop = false;
  mapThumbnail;
  chartsThumbnail;
  loading = false;
  err = false;
  showMap = false;
  showCharts = false;

  constructor(
    private i18nService: I18NService,
    private countryDataThumbnailsService: CountryDataThumbnailsService,
    private screenSizeService: ScreenSizeService
  ) {
    this.refreshIsDesktop();
  }

  ngOnInit() {
    this.loading = true;
    this.countryDataThumbnailsService
      .get()
      .then((thumbnails) => {
        this.loading = false;
        this.mapThumbnail = thumbnails.mapThumbnail;
        this.chartsThumbnail = thumbnails.chartsThumbnail;
      })
      .catch((err) => {
        this.err = true;
      });
  }

  openDataModal = (modal, type) => {
    // The timeout to show the modal is to make sure the modal is reinstated each time is shown
    this.refreshIsDesktop();
    this.showMap = false;
    this.showCharts = false;
    setTimeout(() => {
      if (type === 'map') {
        this.showMap = true;
      } else if (type === 'charts') {
        this.showCharts = true;
      }
      modal.show();
    }, 100);
  };

  refreshIsDesktop() {
    if (this.screenSizeService.isLg() || this.screenSizeService.isXl()) {
      this.isDesktop = true;
    } else {
      this.isDesktop = false;
    }
  }
}
