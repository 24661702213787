import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ServiceModule } from '../service/service.module';
import { HomeRouterModule } from './home-router.module';
import { HomeComponent } from './home.component';
import { HomeSectionComponent } from './components/home-section/home-section.component';
import { HomeSliderModule } from './components/home-slider/home-slider.module';
import { MapModule } from '../map/map.module';
import { KeyDocumentsModule } from '../key-documents/key-documents.module';
import { FaqModule } from '../faq/faq.module'

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    HomeComponent,
    HomeSectionComponent
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    SharedModule,
    ServiceModule,
    HomeRouterModule,
    HomeSliderModule,
    MapModule,
    KeyDocumentsModule,
    FaqModule
  ],

  exports: [
    HomeComponent,
    HomeSectionComponent
  ]
})

export class HomeModule {

}
