import { Injectable } from '@angular/core'
import { ContentfulService } from 'app/service/contentful.service';

@Injectable()
export class FaqQuestionService {

    constructor(private contentfulService: ContentfulService) {
    }

    get() {
      return this.contentfulService.getEntries('faqQuestion')
        .then( (faqQuestions) => this._buildFaqs(faqQuestions.map(item => item.fields)) )
        .then( (faqCategories) => this._alphabeticalOrder(faqCategories));
    }

    _buildFaqs(faqs) {
      var faqCategories = [];
      faqs.forEach((item, i) => {
        var question = {
          question: item.question,
          answer: item.answer
        };
        var categoryPos = this._categoryExists(item.category.fields, faqCategories);
        if (categoryPos > -1) {
          faqCategories[categoryPos].questions.push(question);
        } else {
          var category = item.category.fields;
          category.questions = [question];
          faqCategories.push(category);
        }
      });
      return faqCategories;
    }

    _categoryExists(category, categories) {
      var position = -1;
      var exists = categories.forEach((obj, i) => {
        if (obj.name === category.name) {
          position = i;
          return;
        }
      });
      return position;
    }

    _alphabeticalOrder(categories) {
      return categories.sort(this._dynamicSort('name')).map((category) => {
        category.questions.sort(this._dynamicSort('question'))
        return category;
      });
    }

    _dynamicSort(field) {
      return (a, b) => {
        return (a[field] < b[field]) ? -1 : (a[field] > b[field]) ? 1 : 0;
      };
    }

}
