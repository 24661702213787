import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ContentfulService } from './contentful.service';
import { FirebaseDatabaseService } from './firebase-database.service';
import { DatasourceService } from './datasource.service';
import { CountryDataThumbnailsService } from './country-data-thumbnails.service';
import { EventsService } from './events.service';
import { FooterService } from './footer.service';
import { HeaderMenuService } from './header-menu.service';
import { HomepageService } from './homepage.service';
import { PartnerLogoSliderService } from './partner-logo-slider.service';
import { FaqQuestionService } from './faq-question.service';
import { ResourceService } from './resource.service';
import { StaticPagesService } from './static-pages.service';
import { TextSearchService } from './text-search.service'
import { CustomSearchService } from './custom-search.service'
import { ScreenSizeService } from './screen-size.service'
import { I18NService } from './i18n.service'
import { MetaService } from './meta.service'
import { LangResolver } from './lang.resolver'

@NgModule({
  declarations: [
  ],
  imports: [
    HttpClientModule 
  ],
  providers: [
    ContentfulService,
    FirebaseDatabaseService,
    DatasourceService,
    CountryDataThumbnailsService,
    EventsService,
    FooterService,
    HeaderMenuService,
    HomepageService,
    PartnerLogoSliderService,
    FaqQuestionService,
    ResourceService,
    StaticPagesService,
    TextSearchService,
    CustomSearchService,
    ScreenSizeService,  
    I18NService,
    MetaService,
    LangResolver
  ],
  exports: [

  ]
})

export class ServiceModule {

}
