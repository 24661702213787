import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LangResolver } from 'app/service/lang.resolver';

import { HomeComponent } from './home.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: '', redirectTo: 'en', pathMatch: 'full' },
      { path: 'en', component: HomeComponent, resolve: {lang: LangResolver} },
      { path: 'fr', component: HomeComponent, resolve: {lang: LangResolver} }
    ])
  ],
  exports: [
    RouterModule
  ]
})

export class HomeRouterModule {

}
