import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MetaService } from 'app/service/meta.service';
import { I18NService } from '../service/i18n.service';

@Component({
  templateUrl: './not-found.component.html',
  selector: 'not-found'
})

export class NotFoundComponent implements OnInit {

  constructor(private titleService: Title, private metaService: MetaService, private i18NService: I18NService) {
    this.i18NService.getSingleTranslation('share.soam.text')
      .then((translation) => {
        this.i18NService.getSingleTranslation('share.soam.not-found')
          .then((translation2) => {
            this.titleService.setTitle(translation2 + translation);
            this.metaService.resetMetas();
            this.metaService.addMetas(
              translation2 + translation,
              translation2 + translation,
            );
            this.metaService.addSingleNotFoundMeta();
          });
      });
  }

  ngOnInit() {
  }

}
