import { Injectable } from '@angular/core'
import { ContentfulService } from 'app/service/contentful.service';

@Injectable()
export class HeaderMenuService {

  constructor(private contentfulService: ContentfulService) {
  }

  get() {
    // Return only the first element because there arent more
    return this.contentfulService.getEntries('headerMenu')
      .then((headerMenu) => headerMenu.length ? headerMenu[0].fields : {});
  }

}
