import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MetaService } from 'app/service/meta.service';
import { FaqQuestionService } from '../service/faq-question.service';
import { TextSearchService } from '../service/text-search.service';
import { I18NService } from '../service/i18n.service';
import '../../styles/components/faq.scss';

@Component({
  templateUrl: './faq.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'faq'
})

export class FaqComponent implements OnInit {

  faqCategories = [];
  filteredCategories = []
  queryString: string;
  isLoading: boolean = false;


  constructor(
    private faqQuestionService: FaqQuestionService,
    private textSearchService: TextSearchService,
    private metaService: MetaService,
    private titleService: Title,
    private i18NService: I18NService
  ) {
    this.i18NService.getSingleTranslation('share.soam.text')
      .then((translation) => {
        this.i18NService.getSingleTranslation('share.soam.faq')
          .then((translation2) => {
            this.titleService.setTitle(translation2 + translation);
            this.metaService.resetMetas();
            this.metaService.addMetas(
              translation2 + translation,
              translation2 + translation,
            );
          });
      });
  }

  ngOnInit() {
    this.faqQuestionService.get()
      .then((faq) => {
        this.faqCategories = faq;
        this.filteredCategories = JSON.parse(JSON.stringify(this.faqCategories));
      })
  }

  filter(search) {
    let regexStr = this.textSearchService.buildCombinedSearchText(search)
    if (search === "") {
      this.filteredCategories = JSON.parse(JSON.stringify(this.faqCategories))
    }
    this.faqCategories.forEach(
      (category, index) => {
        this.filteredCategories[index].questions =
          category.questions.filter((item) => {
            let fullQuestion = item.question.concat(item.answer)
            return fullQuestion.match(new RegExp(regexStr, 'gi'))
          })
      }
    )
  }

  loadFilter(event) {
    this.isLoading = true
    setTimeout(() => {
      this.isLoading = false
      this.filter(event)
    }, 1000)
  }
}
