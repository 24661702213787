import { Injectable } from '@angular/core';

@Injectable()
export class AppConfigService {
  public host: String = process.env.HOST;
  public port: Number = parseInt(process.env.PORT);
  public env: String = process.env.ENV;
  public appConfig: Object = process.env.APP_CONFIG;
  // public customConfig: Object;

  constructor() {
    if (!this.appConfig) {
      //const configBuffer = fs.readFileSync(process.cwd() + '/config/environments/dev.env.json');
      //const config = JSON.parse(configBuffer.toString('utf8'));
      //this.appConfig = config.config;
      /*this.appConfig = {
        "contentfulSpace": "",
        "contentfulAccessToken": ""
      };*/
    }
  }
}
