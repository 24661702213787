import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ResourceService } from 'app/service/resource.service';
import { MetaService } from 'app/service/meta.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ScreenSizeService } from 'app/service/screen-size.service';
import { I18NService } from '../../../service/i18n.service';
import { AppConfigService } from 'app/core/services/app-config.service'
import '../../../../styles/components/resource-library.scss'

@Component({
  templateUrl: './document-item.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'document-item'
})

export class DocumentItemComponent implements OnInit {

  @Input() resourceCategory: string;
  document: any;
  paragraphs: any = '';
  colWidth: number;
  cdnUrl: string;


  constructor(
    private resourceService: ResourceService,
    private router: Router,
    private route: ActivatedRoute,
    private metaService: MetaService,
    private screenSizeService: ScreenSizeService,
    private titleService: Title,
    private i18NService: I18NService,
    private appConfigService: AppConfigService
  ) {
      this.colWidth = this.screenSizeService.getDocumentImgWidth();
      this.cdnUrl = appConfigService.appConfig['cdnUrl'];
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['resourceId']) {
        this.resourceService.getById(params['resourceId'])
          .then((resource) => {
            console.log('resource', resource);
            if (resource) {
              this.i18NService.getSingleTranslation('share.soam.text')
                .then((translation) => {
                  this.i18NService.getSingleTranslation('share.soam.resources')
                    .then((translation2) => {
                      this.titleService.setTitle(resource.title);
                      this.metaService.resetMetas();
                      this.metaService.addMetas(
                        resource.title,
                        resource && resource.description ? this._sanitizeString(resource.description) : resource.title,
                        params['resourceId'],
                        resource.publisher,
                        resource && resource.author ? resource.author : null,
                        resource.type,
                        resource && resource.country ? resource.country : null,
                        resource && resource.year ? resource.year : null,
                        resource && resource.documents && resource.documents.length > 0 ? resource.documents : null,
                        resource && resource.image ? 'https:' + resource.image.fields.file.url : null
                      );
                    });
                });
              this.document = resource;
              if (this.document.country) {
                this.resourceService.translateIsoCountriesFromResource(resource.country)
                  .then((countries) => { this.document.translatedCountries = countries; });
              }
              if(this.document.journalsource){
               
               this.paragraphs = this.document.journalsource.content.map((element) => this.createParagraph(element)).join('\n\n');
              }
              if(this.document.documents.length) {

                for(let i = 0; i<this.document.documents.length; i++) {
                  let cdn = this.cdnUrl;
                  let searchStr = '/documents';
                  let searchStr2 = '?alt=';

                  let resDoc = this.document.documents[i].fields.fileUrl;
                  //console.log(resDoc);
                  if(resDoc.indexOf(searchStr)>0) {
                    if(resDoc.indexOf(searchStr+'%2F')>0) {
                      searchStr += '%2F';
                      cdn += '/';
                    }
                    this.document.documents[i].fields.fileUrl = cdn.concat(resDoc.slice(resDoc.indexOf(searchStr)+searchStr.length, resDoc.indexOf(searchStr2)>0?resDoc.indexOf(searchStr2):resDoc.length));
                    //console.log(this.document.documents[i].fields.fileUrl);
                  }
                }
              }
            } else {
              this.router.navigate(['404']);
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      } else {
        this.router.navigate(['404']);
      }
    });
  }

  createParagraph(element){
    if (element.nodeType === 'paragraph'){
      return element.content.map((e) => this.createParagraph(e)).join('');
    }else if(element.nodeType === 'text'){
      return element.value
    }
    else if (element.nodeType === 'hyperlink'){
     
      let text = element.content.reduce((acc, current) => {
         return acc + this.createParagraph(current)        
       }, '') 
      return `<a href=${element.data.uri} > ${text}</a>` ;
    }
  }

  _sanitizeString(s) {
    return s.replace(/<(.|\n)*?>/g, '');
  }

}
