import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class FirebaseDatabaseService {

  private db;

  constructor(angularFireDatabase: AngularFireDatabase) {
    this.db = angularFireDatabase;
  }

  /*
   * Returns an observable corresponding to the list of entries
   * in a table (listPath) in the firebase database
   */
  getList(listPath: string, filterKey?: string, filterValue?: string) {
    return this.db.list(listPath, (ref) => {
      return filterKey && filterValue ?
        ref.orderByChild(filterKey).equalTo(filterValue) : ref;
    }).valueChanges();
  }

}
