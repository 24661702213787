import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LangResolver } from 'app/service/lang.resolver';
import { ResourceLibraryComponent } from './resource-library.component';
import { DocumentItemComponent } from './components/document-item/document-item.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'resource-library', redirectTo: 'en/resource-library', pathMatch: 'full' },
      { path: ':lang/resource-library', component: ResourceLibraryComponent, resolve: { lang: LangResolver } },
      { path: 'resource-library/:resourceId', redirectTo: 'en/resource-library/:resourceId', pathMatch: 'full' },
      { path: ':lang/resource-library/:resourceId', component: DocumentItemComponent, resolve: { lang: LangResolver } },
      { path: 'resource-library/by-country/:country', redirectTo: 'en/resource-library/by-country/:country', pathMatch: 'full' },
      { path: ':lang/resource-library/by-country/:country', component: ResourceLibraryComponent, resolve: { lang: LangResolver } }
    ])
  ],
  exports: [
    RouterModule
  ]
})

export class ResourceLibraryRouterModule {

}
