import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import '../../../../styles/components/static-section.scss';

@Component({
  templateUrl: './static-section.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'static-section'
})

export class StaticSectionComponent {
 burl = ""
 murl = ""
 turl = ""
 clClass  = ""
 crClass  = ""
 size=""
 numCols=""
 sectClass=""
  @Input() section : any;
  @Input() width : number;

  constructor() {}

  ngOnInit(){
    if(this.section.backgroundImage && this.section.backgroundImage.fields){
      this.burl = this.section.backgroundImage.fields.file.url
    }
    if(this.section.mainImage && this.section.mainImage.fields){
      this.murl = this.section.mainImage.fields.file.url
    }
    
    if(this.section.urlLink){
      this.turl = this.section.urlLink
    }
    switch (this.section.imagePosition){
    case "Left":
     this.clClass  = ""
 		 this.crClass  = "align-items-left col-md-7 pr-md-0 pl-md-4 ssimgtxt"
    break;
    case "Right":
     this.clClass  = "order-md-2 align-items--md-end"
 		 this.crClass  = "align-items-left col-md-7 pl-md-0 pr-md-4 ssimgtxt"
    break;
    default:
    	this.clClass  = ""
 		  this.crClass  = "align-items-center pt-md-5 pr-md-0 pl-md-0"
    break;
    }

    switch (this.section.sectionSpacing){
      case "Only Bottom":
        this.sectClass  = "pt-md-0 pb-md-3"
      break;
      case "Only Top":
       this.sectClass  = "pt-md-3 pb-md-0 "
      break;
      case "No Spacing":
        this.sectClass  = "pt-md-0 pb-md-0"
       break;
      default:
        this.sectClass  = "pt-md-3 pb-md-3"
      break;
      }
      switch (this.section.imageBackgoundSize){
        case "cover":
          this.size  = "cover"
        break;
        case "contain":
         this.size  = "contain"
        break;
    
        }
    
       if(this.section.sectionCols){
        this.section.sectionCols.forEach((val)=>{
          
          /*initialize*/
        	val.fields.imagePosition = (val.fields.imagePosition) ? val.fields.imagePosition : 'Center';
          val.fields.imageBackgroundSize = (val.fields.imageBackgroundSize) ? val.fields.imageBackgroundSize : 'none';          
          
          val.bgStyle = val.imgSrc = '';
          if(val.fields.backgroundImage && val.fields.backgroundImage.fields.file.url && val.fields.backgroundImage.fields.file.url > ''){
	          if (val.fields.imageAsBackground === false){          	
	          	val.imgSrc = val.fields.backgroundImage.fields.file.url
	          } else {
	          	val.bgStyle = val.fields.backgroundImage.fields.file.url	             
	          }
          } 
          
          switch (val.fields.imagePosition){
            case "Left":
             val.clClass  = ""
             val.crClass  = "align-items-left col-md-7 pr-md-0 pl-md-0 ssimgtxt"
            break;
            case "Right":
              val.clClass  = "order-md-2 align-items--md-end"
              val.crClass  = "align-items-left col-md-7 pl-md-0 pr-md-0 ssimgtxt"
            break;
            default:
              val.clClass  = ""
              val.crClass  = "align-items-center pt-md-5 pr-md-0 pl-md-0"
            break;
            }

            switch (val.fields.imageBackgroundSize){
              case "cover":              
                val.classHeight = "320px"
                val.bgSize = 'cover'
              break;
              case "contain":
                val.classHeight  = "320px"
                val.bgSize = 'contain'
              break;
          		default: 
          		  val.classHeight = "auto"
          		  val.bgSize = 'inherit'
          		break;
              }
				console.log(val);
        })
        switch (this.section.sectionCols.length){
          case 1:
            this.numCols  = "col-md-12"
          break;
          case 2:
            this.numCols  = "col-md-6"
          break;
          case 3:
            this.numCols  = "col-md-4"
          break;
          case 4:
            this.numCols  = "col-md-3"
          break;
            
        }
       }

  }
}
