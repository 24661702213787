import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import '../../styles/components/countries.scss';
import { AppConfigService } from 'app/core/services/app-config.service';
import { MetaService } from 'app/service/meta.service';
import { EventsService } from '../service/events.service';
import { ResourceService } from 'app/service/resource.service';
import { DatasourceService } from 'app/service/datasource.service';
import { ScreenSizeService } from '../service/screen-size.service';
import { I18NService } from '../service/i18n.service';

@Component({
  templateUrl: './country.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'country'
})

export class CountryComponent implements OnInit {
  datas: any;
  upcomingEvents: any;
  previousEvents: any;
  documents: any;
  params: any;
  loading: boolean = true;
  country: any;
  countryGinaUrl: string;
  colWidth: number;
  pageSize: number;
  resourcePageSize: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    appConfigService: AppConfigService,
    private eventsService: EventsService,
    private resourceService: ResourceService,
    private screenSizeService: ScreenSizeService,
    private datasourceService: DatasourceService,
    private metaService: MetaService,
    private titleService: Title,
    private i18nService: I18NService
  ) {
    this.colWidth = this.screenSizeService.getThumbnailColWidth()
    this.pageSize = appConfigService.appConfig['pagination']['eventsMaxPageSize'];
    this.resourcePageSize = appConfigService.appConfig['pagination']['resourcesMaxPageSize'];
  }

  ngOnInit() {
    this.params = this.route.params;
    this.params.subscribe(params => {
      if (params['country']) {
        this.datasourceService.getCountryNamefromIso(params['country'], this.i18nService.getActiveLang())
          .then((name) => {
            this.i18nService.getSingleTranslation('share.soam.text')
              .then((translation) => {
                this.titleService.setTitle(name);
                this.metaService.resetMetas();
                this.metaService.addMetas(
                  name,
                  name,
                );
              });
            this.country = {code: params['country'], name: name};
            this._setDatas();
          })
          .catch(() => {
            this.loading = false;
            this.router.navigate(['404']);    
          });
      } else {
        this.loading = false;
        this.router.navigate(['404']);
      }
    });
  }

  _setDatas() {
    this.datasourceService.getCountryGinaUrlFromIso(this.country.code)
      .then((ginaUrl) => { 
        this.loading = false;
        if (ginaUrl) { this.countryGinaUrl = ginaUrl; }
      })
    this.eventsService.getUpcomingEvents(this.country.code)
      .then((events) => {
        this.upcomingEvents = events.slice(0, this.pageSize);
      })
      .catch((err) => {
        console.error('error', err)
      });
    this.eventsService.getPreviousEvents(this.country.code)
      .then((events) => {
        this.previousEvents = events;
      })
      .catch((err) => {
        console.error('error', err)
      });
    this.resourceService.get(this.country.code, null, this.resourcePageSize)
      .then((resources) => {
        this.documents = this._cleanDocuments(resources.items.map(resource => {
          resource.fields.id = resource.sys.id;
          return resource.fields;
        }));
      })
      .catch((err) => {
        console.error('error', err)
      });
  }

  _sanitizeString(s) {
    return s.replace(/<(.|\n)*?>/g, '');
  }

  _cleanDocuments(docs) {
    docs.forEach((d) => {
      d.sanitizedTitle = this._sanitizeString(d.title);
    });
    return docs;
  }
}
