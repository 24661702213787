import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { KumuComponent } from './kumu.component';
import { RouterModule } from '@angular/router';

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    KumuComponent
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    SharedModule,
    RouterModule 
  ],

  exports: [
    KumuComponent
  ]
})

export class KumuModule {

}
