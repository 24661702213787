import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from 'app/shared/shared.module';
import { KumuModule } from 'app/kumu/kumu.module';
import { StaticPagesRouterModule } from './static-pages-router.module';
import { StaticPagesComponent } from './static-pages.component';
import { StaticSectionComponent } from './components/static-section/static-section.component';
import { MapModule } from '../map/map.module';

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    StaticPagesComponent,
    StaticSectionComponent    
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    SharedModule,
    KumuModule,
    FormsModule,
    StaticPagesRouterModule,
    KumuModule,
    MapModule
  ],

  exports: [
  	StaticSectionComponent  	
  ]
})

export class StaticPagesModule {

}
