import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/core/services/app-config.service';
import { I18NService } from './i18n.service';
import { createClient } from 'contentful';

@Injectable()
export class ContentfulService {

  private _space: string;
  private _accessToken: string;
  private cdaClient;
  private locale: string;

  constructor(appConfigService: AppConfigService, private i18NService: I18NService) {
    this.locale = this.i18NService.getActiveLang() === 'en' ?
      appConfigService.appConfig['contentefulMainLocale'] : this.i18NService.getActiveLang();
    this._space = appConfigService.appConfig['contentfulSpace'];
    this._accessToken = appConfigService.appConfig['contentfulAccessToken'];
    this.cdaClient = createClient({
      space: this._space,
      accessToken: this._accessToken
    });
  }

  getEntries(contentType: string, query?: any) {
    query = query || {};
    query.include = 6;
    query.locale = this.locale;
    return this.cdaClient.getEntries(
      Object.assign({content_type: contentType}, query)
    )
    .then((entries) => (entries.items && entries.items.length > 0) ? entries.items : []);
  }

  getEntriesWithCount(contentType: string, query?: any) {
    query = query || {};
    query.include = 6;
    query.locale = this.locale;
    return this.cdaClient.getEntries(
      Object.assign({content_type: contentType}, query)
    )
    .then((entries) => (entries.items && entries.items.length > 0) ? {items: entries.items, count: entries.total} : {items: [], count: 0});
  }

  private _getEntriesForRecursion(contentType: string, query: any, locale: string, client: any) {
    query.include = 0;
    query.locale = locale;
    return client.getEntries(
      Object.assign({content_type: contentType}, query)
    );
  }

  getAllEntries(contentType: string, query?: any) {
    let page = 0;
    query['skip'] = page;
    query['limit'] = 100;
    let func = (contentType, query, locale, client, objects, callback) => {
      return callback(contentType, query, locale, client)
        .then((result) => {
          result.items.forEach((item) => { objects.push(item); });
          if (result.items.length < result.total) {
            page++;
            query.skip = page * 100;
            return func(contentType, query, locale, client, objects, callback);
          } else {
            return Promise.resolve(objects);
          }
        });
    };
    return func(contentType, query, this.locale, this.cdaClient, [], this._getEntriesForRecursion);
  }

  getEntry(contentType: string, query?: any) {
    query = query || {};
    query.include = 6;
    query.locale = this.locale;
    return this.cdaClient.getEntries(
      Object.assign({content_type: contentType}, query)
    )
    .then((entries) => (entries.items && entries.items.length) ? entries.items[0].fields : undefined);
  }

}
