import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppConfigService } from 'app/core/services/app-config.service';
import '../../styles/components/countries.scss';
import { MetaService } from 'app/service/meta.service';
import { EventsService } from '../service/events.service';
import { ResourceService } from 'app/service/resource.service';
import { DatasourceService } from '../service/datasource.service';
import { ScreenSizeService } from '../service/screen-size.service';
import { I18NService } from '../service/i18n.service';

@Component({
  templateUrl: './countries.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'countries'
})

export class CountriesComponent implements OnInit {

  datas = [1, 2, 3, 4]
  upcomingEvents: any;
  previousEvents: any;
  documents: any;
  countries: Array<any> = [];
  params: any;
  colWidth: number;
  pageSize: number;
  resourcePageSize: number;

  constructor(
    appConfigService: AppConfigService,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private resourceService: ResourceService,
    private screenSizeService: ScreenSizeService,
    private datasourceService: DatasourceService,
    private metaService: MetaService,
    private titleService: Title,
    private i18NService: I18NService,
  ) {
    this.i18NService.getSingleTranslation('share.soam.text')
      .then((translation) => {
        this.i18NService.getSingleTranslation('share.soam.countries')
          .then((translation2) => {
            this.titleService.setTitle(translation2 + translation);
            this.metaService.resetMetas();
            this.metaService.addMetas(
              translation2 + translation,
              translation2 + translation,
            );
          });
      });
    this.colWidth = this.screenSizeService.getThumbnailColWidth()
    this.pageSize = appConfigService.appConfig['pagination']['eventsMaxPageSize'];
    this.resourcePageSize = appConfigService.appConfig['pagination']['resourcesMaxPageSize'];
  }

  ngOnInit() {
    this.params = this.route.params
    this.eventsService.getUpcomingEvents('GLOBAL')
      .then((events) => {
        this.upcomingEvents = events.slice(0, this.pageSize);
      })
      .catch((err) => {
        console.error('error', err)
      });

    this.eventsService.getPreviousEvents('GLOBAL')
      .then((events) => {
        this.previousEvents = events;
      })
      .catch((err) => {
        console.error('error', err)
      });
    this.resourceService.get(null, null, this.resourcePageSize)
      .then((resources) => {
        this.documents = this._cleanDocuments(resources.items.map(resource => {
          resource.fields.id = resource.sys.id;
          return resource.fields;
        }));
      })
      .catch((err) => {
        console.error('error', err)
      });
  }

  _sanitizeString(s) {
    return s.replace(/<(.|\n)*?>/g, '');
  }

  _cleanDocuments(docs) {
    docs.forEach((d) => {
      d.sanitizedTitle = this._sanitizeString(d.title);
    });
    return docs;
  }
}
