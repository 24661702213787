import { Component, Input } from '@angular/core';
import '../../styles/components/kumu.scss';
import { AppConfigService } from 'app/core/services/app-config.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
    templateUrl: './kumu.component.html',
    selector: 'kumu'
})

export class KumuComponent {

    _kumuUrl: any;
    
    constructor(private appConfigService: AppConfigService,
                private _sanitizer: DomSanitizer) {
        this._kumuUrl = this._sanitizer.bypassSecurityTrustResourceUrl(appConfigService.appConfig['kumuUrl']);
    }
}
