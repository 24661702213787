import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { AppConfigService } from 'app/core/services/app-config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ResourceService } from '../service/resource.service';
import { MetaService } from 'app/service/meta.service';
import { CustomSearchService } from '../service/custom-search.service';
import { I18NService } from '../service/i18n.service';
import '../../styles/components/resource-library.scss';

@Component({
  templateUrl: './resource-library.component.html',
  selector: 'resource-library'
})

export class ResourceLibraryComponent implements OnInit {

  savedDocuments: Array<any>;
  documents: Array<any> = [];
  searching: boolean = false;
  query: any = {};
  countries: Array<string> = [];
  types: Array<string> = [];
  years: Array<string> = [];
  languages: Array<string> = [];
  isLoading: boolean = false;
  page: number = 0;
  savedPage: number = 0;
  pageSize: number;
  noMore: boolean = false;
  savedNoMore: boolean = false;
  useGCS: boolean = false;
  isByCountry: boolean = false;
  loadingFilters: boolean = false;
  totalDocuments: number = 0;
  savedTotalDocuments: number = 0;

  constructor(
    appConfigService: AppConfigService,
    private customSearchService: CustomSearchService,
    private route: ActivatedRoute,
    private router: Router,
    private resourceService: ResourceService,
    private metaService: MetaService,
    private titleService: Title,
    private i18NService: I18NService
  ) {
      this.i18NService.getSingleTranslation('share.soam.text')
        .then((translation) => {
          this.i18NService.getSingleTranslation('share.soam.resources')
            .then((translation2) => {
              this.titleService.setTitle(translation2 + translation);
              this.metaService.resetMetas();
              this.metaService.addMetas(
                translation2 + translation,
                translation2 + translation,
              );
            });
        });
      this.pageSize = appConfigService.appConfig['pagination']['resourcesMaxPageSize'];
      this.useGCS = appConfigService.appConfig['gcsUseForSearch'];
  }

  ngOnInit() {
    this.isLoading = true;
    this.loadingFilters = true;
    // Get filters
    let filterPromises = [];
    filterPromises.push(
      this.resourceService.getCountries()
        .then(countries => { this.countries = countries; })
    );
    filterPromises.push(
      this.resourceService.getTypes()
        .then(types => { this.types = types; })
    );
    filterPromises.push(
      this.resourceService.getYears()
        .then(years => { this.years = years; })
    );
    filterPromises.push(
      this.resourceService.getLangs()
        .then(languages => { this.languages = languages; })
    );
    Promise.all(filterPromises)
      .then(() => { this.loadingFilters = false; });
    // Get resources
    this.route.params.subscribe(params => {
      if (params['country']) {
        this._getResources(params['country'])
        this.query.country = params['country']
        this.isByCountry = true
      }
      else {
        this._getResources();
      }
    })
  }

  _getResources(country?: string) {
    this.isLoading = true;
    this.resourceService.get(country || null, this.page * this.pageSize, this.pageSize)
      .then(resources => {
        this.page++;
        this.isLoading = false;
        this.documents = this.documents.concat(this._cleanDocuments(resources.items.map(resource => {
          resource.fields.id = resource.sys.id;
          return resource.fields;
        })));
        this.noMore = resources.items.length < this.pageSize;
        this.totalDocuments = resources.count;
        this.savedTotalDocuments = this.totalDocuments;
        this.savedDocuments = this.documents;
        //this.documents = this.initialDocuments.slice(0, this.pageSize);
      });
  }

  search(reset?: boolean) {
    if (reset) {
      // First search reset documents
      this.savedNoMore = this.noMore;
      this.savedPage = this.page;
      this.documents = [];
      this.page = 0;
    }
    this.isLoading = true;
    this.searching = true;
    if (this.useGCS) {
      this._searchByGCS();
    } else {
      this._searchByCF();
    }
  }

  _searchByGCS() {
    let query = this.query.text ? this.query.text.replace(/ /g, '%20') : '';
    if (this.query.country) {
      query += '+more:pagemap:metatags-country:' + this.query.country;
    }
    if (this.query.language) {
      query += '+more:pagemap:metatags-document-language:' + this.query.language;
    }
    if (this.query.type) {
      query += '+more:pagemap:metatags-type:' + this.query.type.replace(/ /g, '%20');
    }
    if (this.query.year) {
      query += '+more:pagemap:metatags-date:' + this.query.year;
    }
    // Transform this.query into the string and filters required
    this.customSearchService.get(query, this.page, this.pageSize)
      .then((data) => { 
        this.page++;
        this.isLoading = false;
        this.documents = this.documents.concat(this._cleanDocuments(data.map((resource) => {
          let newResource = {};
          newResource['id'] = resource['og:id'];
          newResource['title'] = resource['og:title'];
          newResource['author'] = resource['og:author'];
          newResource['date'] = resource['og:date'];
          newResource['type'] = resource['og:type'];
          newResource['fileUrl'] = resource['og:url'];
          return newResource;
        })));
        this.noMore = data.length < this.pageSize;
      })
      .catch((err) => { 
        this.isLoading = false;
        console.log('err', err); 
      });
  }

  _searchByCF() {
    this.resourceService.searchResources(this.query, this.page * this.pageSize)
      .then((resources) => {
        this.page++;
        this.isLoading = false;
        this.documents = this.documents.concat(this._cleanDocuments(resources.items.map(resource => {
          resource.fields.id = resource.sys.id;
          return resource.fields;
        })));
        this.noMore = resources.items.length < this.pageSize;
        this.totalDocuments = resources.count;
      })
      .catch((err) => { 
        this.isLoading = false;
        console.log('err', err); 
      });
  }

  clearSearch(){
    if(this.isByCountry){
      this.router.navigate(['resource-library']);
    }
    this.searching = false;
    this.query = {};
    this.documents = this.savedDocuments;
    this.noMore = this.savedNoMore;
    this.page = this.savedPage;
    this.totalDocuments = this.savedTotalDocuments;
  }

  _sanitizeString(s) {
    return s.replace(/<(.|\n)*?>/g, '');
  }

  _cleanDocuments(docs) {
    docs.forEach((d) => {
      d.sanitizedTitle = this._sanitizeString(d.title);
    });
    return docs;
  }

  _isQueryEmpty(){
    return Object.keys(this.query).length === 0
  }

  loadMore() {
    if (this.searching) {
      this.search();
    } else {
      this._getResources();
    }
  }
}
