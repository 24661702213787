import {Component, ViewEncapsulation, Input, OnInit} from '@angular/core';
import {ResourceService} from 'app/service/resource.service';
import {DatasourceService} from 'app/service/datasource.service';
import {AppConfigService} from 'app/core/services/app-config.service';
import {Chart} from 'chart.js';
import * as _ from 'underscore';
@Component({
  templateUrl: './chart.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'chart'
})
export class ChartComponent {
  chart = [];
  chartWidth: number;
  needColor: string;
  treatedColor: string;

  @Input() datas: any;

  constructor(private datasourceService: DatasourceService, private appConfigService: AppConfigService) {
    this.needColor = this.appConfigService.appConfig['chartConfig']['needColor'];
    this.treatedColor = this.appConfigService.appConfig['chartConfig']['treatedColor'];
  }

  ngOnInit() {
    let samChart = document.getElementById('samChart');

    this.datasourceService.getChartData().then((res) => {
      this.chart = new Chart(samChart, {
        type: 'line',
        data: {
          labels:res.years,
          datasets: [
            {
              data: res.needs,
              borderColor: this.needColor,
              fill: false
            },
            {
              data: res.treated,
              borderColor: this.treatedColor,
              fill: false
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          },
          responsive: true,
          maintainAspectRatio: true,
          spanGaps: true,
          legend: {
            display: false
          }
        }
      });
    });
  }
}
