import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { DatasourceService } from 'app/service/datasource.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { I18NService } from '../../service/i18n.service';
import '../../../styles/components/databar.scss';


@Component({
  templateUrl: './databar.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'databar'
})

export class DatabarComponent implements OnInit {
  datas: any;
  @Input() country: string;
  @Input() countryName: string;
  @Input() countryGinaUrl: string;
  fields: any;
  lang: string;

  constructor(private route: ActivatedRoute,
    private datasourceService: DatasourceService,
    private i18NService: I18NService
  ) {
    this.lang = this._getActiveLang();
  }

  ngOnInit() {
    if (this.country) {
      this.datasourceService.getCountryDatabarData(this.country, this.lang)
        .then(data => {
          this.datas = data.map((x, index )=> {
            if (x['dataType'] !== 'percentage' && x['value'].trim() !== '') {              
              x['value'] = (index === 0) ? this._truncateNumber(x['value'])  : this._formatNumber(x['value']);            
            } else if (x['dataType'] === 'percentage' && x['value'].trim() !== '') {          
              x['value'] = (index === 0) ? this._truncateNumber(x['value']) : this._formatNumber(x['value'])+ '%'
            }
            return x;
          });
        });
    }
    /*
    this.datasourceService.getDataFields()
      .then((dataFields) => {
        this.fields = dataFields
        let promise = this.country ? 
          this.datasourceService.getCountryRecentData(this.country) :
          this.datasourceService.getGlobalAggregatedData();
        promise.then(data => this._buildDataToShow(data));
      });
    */
  }

  private _getActiveLang() {
    return this.i18NService.getActiveLang();
  }

  /*
  _buildDataToShow(data) {
    let dataToShow = []
    let dataArray = Object.keys(data)
    dataArray.forEach((key) => {
      if (dataToShow.length < 4) {
        if (data[key]) {
          let titleKey = 'English Title:';
          if (this.lang !== 'en') {
            titleKey = 'French Title:'
          }
          let keyToDisplay = this.fields ? this.fields[key][titleKey] : key
          dataToShow.push(
            {
              name: keyToDisplay,
              value: this._formatNumber(data[key].value),
              year: data[key].year
            })
        }
      }
      else {
        return
      }
    })
    this.datas = dataToShow
  }
  */

  _formatNumber(number) {
    if (number == 0) {
      return 0;
    }
    else {
     
      if (number <= 999) {        
        return (number % 1 === 0)? Math.round(number) : this._round(number);         
      }
      else if (number >= 1000 && number <= 999999) {
        return this._round(number / 1000) + 'K';
      }
      else if (number >= 1000000 && number <= 999999999) {
        return this._round(number / 1000000)  + 'M';
      }
      else if (number >= 1000000000) {
        return this._round(number / 1000000000)  + 'B';
      }
      else {
        return Math.round(number);
      }
    }
  }

  _round(number) {  
    return Math.round(number) ;  
  }

  _truncateNumber(number){
    let x = Math.round(number * 100) / 100
    return x.toFixed(1);

  }

 
}
