
import { Component, OnInit, Input, OnChanges ,SimpleChanges} from '@angular/core';
import { AppConfigService } from 'app/core/services/app-config.service';
import { ScreenSizeService } from 'app/service/screen-size.service';
import { I18NService } from '../../../service/i18n.service';
import {DatasourceService} from '../../../service/datasource.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-graph5',
  templateUrl: './graph5.component.html'
})
export class Graph5Component implements OnInit,OnChanges {

  @Input() country: string;  
  @Input() chart: any;
  @Input() type: any;
  baseUrl: string;

  hostUrl: string;
  tableauImg: string;
  tableauName: string;
  tableauScript: any = null;
  tableauDiv: any = null;subcategories: Array<any> = [];
  loading: boolean = false;
  showTableau: boolean = false;
  recentlyOpen: boolean = true;
  activeLang: string;
  selectedRegion: any;
  selectIndicatorX: any;
  selectIndicatorY: any;
  selectCountry: any;
  selectYear: any;
  years: any;
  indicators: Array<any> = [];
  indicatorsX: Array<any> = [
    {
    value: "sam_prevalence",
  nameX: "SAM Prevalence (%)"},
  {
    value: "gam_prevalence",
nameX: "GAM Prevalence (%)"
  },
  {
  value: "total_admissions",
  nameX: "Admissions for SAM Treatment (number)"
}];
  indicatorsY: Array<any> = [];
  indPyear: any;
  countPyear: Array<any> = [];
  countries$: any;
  originalPercentages: Array<any> = [];
  originalNumerics: Array<any> = [];
  originalBooleans: Array<any> = [];
  listCountry: any;
  regions: Array<any> = [];

  constructor(
    private appConfigService: AppConfigService,
    private screenSizeService: ScreenSizeService,
    private i18nService: I18NService,
    private datasourceService: DatasourceService
  ) { }

  ngOnChanges(changes: SimpleChanges){
    if(_.isUndefined(this.type)){
        this.type='numeric'
    }
    this.initialization();
  
  }
  ngOnInit(){ }

  initialization(){
    this.loading = true;
    this.activeLang = this.i18nService.getActiveLang();
    this.showTableau = false;
    this.recentlyOpen = true;    
    this.selectYear= this.appConfigService.appConfig['data']['defaultYear'];
    this.getNationalData();
    this.datasourceService.getIndicatorsXY(this.activeLang)
    .then((response)=>{
        this.indicators = response;
        this.selectIndicatorX= this.indicatorsX[0].nameX;
        this.selectIndicatorY =_.first(_.where(this.indicators, {nameX: this.selectIndicatorX}).filter(value => value.nameY !== this.selectIndicatorX)).nameY;
  })
    this.countriesByRegion();
    this.baseUrl = this.appConfigService.appConfig['tableauConfig']['baseUrl'];
    let scriptElement = document.createElement('script');
    scriptElement.src = this.appConfigService.appConfig['tableauConfig']['src'];
    this.tableauScript = scriptElement;
    this.tableauDiv = document.getElementById('tableau6');  
    this.callTableau();
    setTimeout(() => {
      this.loading = false;
      
    }, 1000);
  }
  callTableau() {
  let name = this.chart.name;
    this.hostUrl = this.appConfigService.appConfig['tableauConfig']['hostUrl'];
    this.tableauName = name;
    let iframeElement = this.tableauDiv.getElementsByTagName('iframe')[0];
    if (!iframeElement ) {
      
      let vizElement = this.tableauDiv.getElementsByTagName('object')[0];
      if (!vizElement) {
        this.tableauDiv = document.getElementById('tableau6');
        vizElement = this.tableauDiv.getElementsByTagName('object')[0];
      }
      vizElement.style.width='100%';
      if (this.screenSizeService.isLg() || this.screenSizeService.isXl()) {
        vizElement.style.height=(400) + 'vh';
         vizElement.style.height='800px';
        vizElement.style.height=(this.tableauDiv.offsetWidth*0.75)+'px';
      } else {
        vizElement.style.height=(400)+'px';
      }
      vizElement.parentNode.insertBefore(this.tableauScript, vizElement);
      this.recentlyOpen = false;
    } else {
      let newHostUrl = this.baseUrl + this.tableauName + '?:embed=y&:showVizHome=no&:host_url=';
     
      newHostUrl += this.hostUrl + '&:embed_code_version=3&:tabs=no&:toolbar=no&:animate_transition=yes&:display_static_image=yes';
      newHostUrl += '&:display_spinner=yes&:display_overlay=yes&:display_count=yes&:linktarget=_blank';
      newHostUrl += '&Language=' + (this.activeLang === 'fr' ? 'French' : 'English');
      newHostUrl += '&:loadOrderID=0';
      if (this.country) {
        newHostUrl += '&Country=' + this.country
      }
      if (this.selectYear){
        newHostUrl += '&Year(Year)=' + this.selectYear;
      }
      if (this.selectIndicatorY){
        newHostUrl += '&Indicator=' + this.selectIndicatorY.replace('%', '%25');
      }
      if(this.selectedRegion && this.selectedRegion[0]!=='all'){
      
        newHostUrl += '&Region=' + this.selectedRegion.toString().replace('%', '%25');
      }

      if(this.selectIndicatorX){
        newHostUrl += '&Select X-axis indicator=' + this.selectIndicatorX.replace('%', '%25');
      }
      if(this.selectIndicatorY){
        newHostUrl += '&Select Y-axis indicator=' + this.selectIndicatorY.replace('%', '%25');
      }


      iframeElement.setAttribute('src', newHostUrl);
    }

    setTimeout(() => {
      this.showTableau = true;
    }, 3000);
   
  }
getNationalData(){ 
  this.datasourceService.getGlobalYear().then((years)=>{
    this.years = years;
  })
}
  fillCountries($event){
    this.countries$ = this.datasourceService.countriestByRegion(this.regions,this.selectedRegion);
    this.callTableau();
  }
  setCountries(){
    this.country = this.selectCountry.toString();
    this.callTableau();
  }
  clearFilters(){
    this.indPyear=[];
    this.selectYear=null;
    this.selectIndicatorY=null;
    this.countPyear=[];
  }
  getAllIndicators(){
    this.datasourceService.getAllIndicatorCharts(this.activeLang)
    .then((response)=>{
      this.indPyear = _.where(response, {dataType: this.type, type:'national'})
    })
  }
  countriesByRegion(){
    this.datasourceService.getCountryNamesOfData()
    .then((response)=>{

      response.forEach((value)=>{
        value.all = 'all'
      })
      this.regions= response;
    })
  }
  getYIndicators($even)
  {
    this.indicatorsY = _.where(this.indicators, {nameX: $even.nameX}).filter(value => value.nameY !== $even.nameX)
    console.log(this.indicatorsY);
  }
}
