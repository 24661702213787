import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'app/core/services/app-config.service';

@Injectable()
export class CustomSearchService {

  private _baseUrl: string;
  private _engineId: string;
  private _apiKey: string;

  constructor(appConfigService: AppConfigService, private http: HttpClient) {
    this._baseUrl = appConfigService.appConfig['gcsBaseURL'];
    this._engineId = appConfigService.appConfig['gcsEngineId'];
    this._apiKey = appConfigService.appConfig['gcsApiKey'];
  }

  get(query?: string, page?: number, limit?: number): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      let url = this._baseUrl + '?key=' + this._apiKey
      url += '&rsz=filtered_cse';
      url += page !== null && limit !== null ? '&num=' + limit : '';
      url += page !== null && limit !== null ? '&start=' + ((page * limit) + 1) : '';
      url += '&cx=' + this._engineId;
      url += '&q=' + query;
      console.log('url', url);
      this.http.get(url)
        .subscribe(
          data => {
            console.log('data', data);
            if (data['items']) {
              resolve(data['items'].map(item => item.pagemap.metatags[0])/*.filter(item => item.title)*/);
            }
            // No results
            resolve([]);
          },
          err => { reject(err); }
        );
    });
  }
}
