import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router'
import { I18NService } from 'app/service/i18n.service';

@Injectable()
export class LangResolver implements Resolve<Object> {
    constructor(
        private router: Router,
        private i18nService: I18NService,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let urlLang = route.url[0].path
        let activeLang = this.i18nService.getActiveLang();
        let newUrl = route.url.map(segment => segment.path)
        if (activeLang === 'fr' && urlLang !== 'fr') {
          newUrl[0] = 'fr';
          this.router.navigate(newUrl)
        } else if (activeLang !== 'fr' && urlLang !== 'en') {
          newUrl[0] = 'en';
          this.router.navigate(newUrl)
        }
        return true;
    }
}
