import { Component, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { routerAnimation } from './router.animations';
import { filter } from 'rxjs/operators';
import '../styles/main.scss';
import { Observable } from 'rxjs';
@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [ routerAnimation ],
})

// APP-COMPONENT
export class AppComponent {

  private viewContainerRef: ViewContainerRef;
  public currentRouteName: String = '';

  public constructor(
      viewContainerRef: ViewContainerRef,
      private route: ActivatedRoute,
      private router: Router
      ) {
    // You need this small hack in order to catch application root view container ref
    this.viewContainerRef = viewContainerRef;
  }

  ngOnInit() {
    const endEvents =this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>

    endEvents.subscribe(event => {
      let currentRoute = this.route.root;
      while (currentRoute.children[0] !== undefined) {
        currentRoute = currentRoute.children[0];
      }
      this.currentRouteName = currentRoute.snapshot.data['name'];
    })
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  onDeactivate() {
    window.scroll(0,0);
  }

}
