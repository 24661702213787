import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LangResolver } from 'app/service/lang.resolver';

import { StaticPagesComponent } from './static-pages.component';
import { NotFoundComponent } from './../not-found/not-found.component';

@NgModule({
  providers: [],
  imports: [
    RouterModule.forChild([
      { path: '404', redirectTo: 'en/404', pathMatch: 'full' },
      { path: ':lang/404', component: NotFoundComponent, resolve: {lang: LangResolver} },
      { path: ':name', redirectTo: 'en/:name', pathMatch: 'full' },
      { path: ':lang/:name', component: StaticPagesComponent, resolve: {lang: LangResolver}}
    ])
  ],
  exports: [
    RouterModule
  ]
})

export class StaticPagesRouterModule {

}
