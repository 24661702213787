import { Injectable } from '@angular/core'


@Injectable()
export class TextSearchService {

  constructor() {
  }


    buildRegularExpression(textSearch) {
      let regularExpression = '';

      let text = textSearch.toLowerCase();
      let size = text.length;
      for (let i = 0; i < size; i++) {
        switch (text[i]) {
          case 'a':
          case 'â':
          case 'ä':
          case 'à':
          case 'å':
          case 'á':
          case 'ã':
            regularExpression += '[a|â|ä|à|å|á|ã]';
            break;

          case 'e':
          case 'é':
          case 'ê':
          case 'ë':
          case 'è':
            regularExpression += '[e|é|ê|ë|è]';
            break;

          case 'i':
          case 'ï':
          case 'î':
          case 'ì':
          case 'í':
            regularExpression += '[i|ï|î|ì|í]';
            break;

          case 'o':
          case 'ð':
          case 'ò':
          case 'ó':
          case 'ô':
          case 'õ':
          case 'ö':
            regularExpression += '[o|ð|ò|ó|ô|õ|ö]';
            break;

          case 'u':
          case 'ù':
          case 'ú':
          case 'û':
          case 'ü':
            regularExpression += '[u|ù|ú|û|ü]';
            break;

          default:
            regularExpression += text[i];
        }
      }

      return regularExpression;
    }

    buildCombinedSearchText(textSearch) {
      let regEx = this.buildRegularExpression(textSearch);
      let searchExp = regEx.split(' ').map(function(currentSearchExp) {
        return currentSearchExp;
      }).join(')|(');
      return '(' + searchExp + ')';
    }



}
