import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LangResolver } from 'app/service/lang.resolver';
import { EventsComponent } from './events.component';
import { EventDetailComponent } from './event-detail.component';
import { BlogDetailComponent } from './blog-detail.component'

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'events', redirectTo: 'en/events', pathMatch: 'full' },
      { path: ':lang/events', component: EventsComponent, resolve: {lang: LangResolver} },
      
      { path: 'events/:eventId', redirectTo: 'en/events/:eventId', pathMatch: 'full' },
      { path: ':lang/events/:eventId', component: EventDetailComponent, resolve: {lang: LangResolver} },
      
      { path: 'events/by-country/:country', redirectTo: 'en/events/by-country/:country', pathMatch: 'full'},
      { path: ':lang/events/by-country/:country', component: EventsComponent, resolve: {lang: LangResolver}},

      { path: 'blogs/:slangurl', redirectTo: 'en/blogs/:slangurl', pathMatch: 'full' },
      { path: ':lang/blogs/:slangurl', component: BlogDetailComponent, resolve: {lang: LangResolver} },
    ])
  ],
  exports: [
    RouterModule
  ]
})

export class EventsRouterModule {

}
