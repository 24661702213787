import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ShareButtonModule } from '@ngx-share/button';
import { SharedModule } from 'app/shared/shared.module';
import { ResourceLibraryComponent } from 'app/resource-library/resource-library.component';
import { ResourceLibraryRouterModule } from './resource-library-router.module';
import { DocumentItemComponent } from './components/document-item/document-item.component';

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    ResourceLibraryComponent,
    DocumentItemComponent
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    SharedModule,
    ResourceLibraryRouterModule,
    FormsModule,
    ShareButtonModule
  ],

  exports: [
    ResourceLibraryComponent,
    DocumentItemComponent 
  ]
})

export class ResourceLibraryModule {

}
