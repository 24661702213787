import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LangResolver } from 'app/service/lang.resolver';
import { FaqComponent } from './faq.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'faq', redirectTo: 'en/faq', pathMatch: 'full' },
      { path: ':lang/faq', component: FaqComponent, resolve: {lang: LangResolver} }
    ])
  ],
  exports: [
    RouterModule
  ]
})

export class FaqRouterModule {

}
