import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { I18NService } from '../service/i18n.service';
import { MetaService } from 'app/service/meta.service';
import { EventsService } from '../service/events.service';
import { ScreenSizeService } from '../service/screen-size.service';
import { AppConfigService } from 'app/core/services/app-config.service';
import * as moment from 'moment';
import '../../styles/components/events.scss';
import * as _ from 'underscore';

@Component({
  selector: 'blog-detail',
  templateUrl: './blog-detail.component.html',
})
export class BlogDetailComponent implements OnInit {
  blog : any = {};
  colWidth: number;
  sizes: any;

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private i18NService: I18NService,
    private eventsService: EventsService,
    private metaService: MetaService,
    private screenSizeService: ScreenSizeService,
    private titleService: Title,
    private appConfigService: AppConfigService) {
      this.sizes = this.appConfigService.appConfig['sizes']
  }


  ngOnInit() {
    this.colWidth = this._getColWidth()
   this.route.queryParams

    this.route.params.subscribe(params => {
      if (params['slangurl']) {
        this.eventsService.getBlogByUrl(params['slangurl'])
          .then((blog) => {
            if (blog) {
              const sanitizedTitle = this._sanitizeString(blog.title);
              const sanitizedContent =blog.content //this._createContent(blog.content.content[0]);
              if(blog.categories){
                const unionCategories = this._concatCategries(blog.categories);
                blog.unoinCategories = unionCategories;
              }
             
              this.i18NService.getSingleTranslation('share.soam.text')
                .then((translation) => {
                  this.titleService.setTitle(sanitizedTitle);
                  this.metaService.resetMetas();
                  this.metaService.addMetas(
                    sanitizedTitle,
                    sanitizedContent,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    blog.mainImage ? 'https:' + blog.mainImage.fields.file.url : null
                  );
                });
              blog.startDateFormatted = this._formatDate(blog.publishedDate);
            //  blog.calendarLink = this._getCalendarLink(event);
              blog.sanitizedTitle = sanitizedTitle;
              blog.sanitizedContent = sanitizedContent;
              blog.url = document.location.origin + '/' + blog.urlSlug
                     
              this.blog = blog;
            } else {
              this.router.navigate(['404'])
            }
          });
      } else {
        this.router.navigate(['404'])
      }

      
    });
  }




  _formatDate(dateString) {
    var d = new Date(dateString);
    return moment(dateString).format('MMMM DD, YYYY');
  }

  _sanitizeString(s) {
    return s.replace(/<(.|\n)*?>/g, '');
  }

  _formatString(s) {
    return encodeURI(s.replace(/<(.|\n)*?>/g, ''));
  }
  
  _getColWidth(){
    let sizes = this.sizes
    if(this.screenSizeService.isXl()){
        return sizes.containerWidthXl / 3
    }
    else if (this.screenSizeService.isLg()){
        return sizes.containerWidthLg*0.6
    }
    else if (this.screenSizeService.isMd()){
        return sizes.containerWidthMd*0.6
    }
    else if (this.screenSizeService.isSm()){
      return sizes.containerWidthSm*0.6
    }
    else {
        return Math.ceil(this.screenSizeService.getWidth()*0.6)
    }
}

_createContent (c){
  var content = '';
  c.content.forEach(value => {
    content = content + value.value
  });
  return this._sanitizeString(content) ;
}

_concatCategries(categories){
  
   var namesCategories = [];
   categories.forEach(cat => {
     var aux = cat.fields.categoryTitle[0].toUpperCase() + cat.fields.categoryTitle.substr(1).toLowerCase();
     namesCategories.push(aux)
   });
   return namesCategories.join(' , ');
}

}
