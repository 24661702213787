import { Injectable } from '@angular/core'
import { ContentfulService } from 'app/service/contentful.service';

@Injectable()
export class FooterService {

  constructor(private contentfulService: ContentfulService) {
  }

  get() {
    // Return only the first element because there arent more
    return this.contentfulService.getEntries('footer')
      .then((footer) => footer.length ? footer[0].fields : {});
  }

}
