import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { FaqRouterModule } from './faq-router.module';
import { FaqComponent } from 'app/faq/faq.component';

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    FaqComponent
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    FaqRouterModule
  ],

  exports: [
    FaqComponent
  ]
})

export class FaqModule {

}
