import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { HomeSliderComponent } from './home-slider.component';

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    HomeSliderComponent
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    SharedModule
  ],

  exports: [
    HomeSliderComponent
  ]
})

export class HomeSliderModule {

}
