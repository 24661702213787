import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { NewsletterWidgetComponent } from 'app/newsletter-widget/newsletter-widget.component';

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    NewsletterWidgetComponent
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],

  exports: [
    NewsletterWidgetComponent
  ]
})

export class NewsletterWidgetModule {

}
