import { Injectable } from '@angular/core';
import { ContentfulService } from 'app/service/contentful.service';

@Injectable()
export class StaticPagesService {

  constructor(private contentfulService: ContentfulService) {
  }

  getByURL(url: string) {
    let query = {};
    query['fields.staticPageUrl'] = url
    return this.contentfulService.getEntries('staticPage', query)
      .then((staticPages) => staticPages[0].fields);
  }

}
