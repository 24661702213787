import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatasourceService } from 'app/service/datasource.service';
import { I18NService } from 'app/service/i18n.service';
import '../../../styles/components/countries.scss';
import * as _ from 'underscore';
import { CountriesModalComponent } from 'app/shared/country-list/countries-modal.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  templateUrl: './country-list.component.html',
  encapsulation: ViewEncapsulation.None,
  selector: 'country-list'
})

export class CountryListComponent implements OnInit {

conuntriesModal:CountriesModalComponent 
countries: any;
list: any
showList: Boolean = false;
//@ViewChild('CountriesModalComponent',{static: false}) 
  constructor(private datasourceService: DatasourceService, private i18nService: I18NService, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.datasourceService.getCountryNamesOfData(this.i18nService.getActiveLang())
      .then((countries)=> { this.countries = countries 
      });
      
  }

  openModal(region ) {
    const modalRef = this.modalService.open(CountriesModalComponent,
      {
        size:'lg',
        centered: true,
      });

    modalRef.componentInstance.data = _.findWhere( this.countries, {code: region});
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }



 

}
