import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import '../../styles/components/newsletter-widget.scss';
import * as $ from 'jquery';

@Component({
  templateUrl: './newsletter-widget.component.html',
  selector: 'newsletter-widget',
  encapsulation: ViewEncapsulation.None
})

export class NewsletterWidgetComponent implements OnInit {

  mailchimpElement;

  constructor() {
  }

  ngOnInit() {
  }

  openModal() {
    $('#PopupSignupForm_0').show(0, () => {
      window.dispatchEvent(new Event('resize'));
    });
    $('#btn-subscribe').hide(0);
  }

}
