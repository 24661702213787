import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormWizardModule } from 'angular2-wizard';
import { SharedModule } from 'app/shared/shared.module';
import { ChartsComponent } from 'app/charts/charts.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { ChecklistModule } from 'angular-checklist';
import * as _ from 'underscore';
import { Graph1Component } from './tenCharts/graph1/graph1.component';
import { Graph5Component } from './tenCharts/graph5/graph5.component';
import { Graph7Component } from './tenCharts/graph7/graph7.component';
import { Graph9Component } from './tenCharts/graph9/graph9.component';
import { Graph10Component } from './tenCharts/graph10/graph10.component';
@NgModule({
  // Components, Pipes, Directive
  declarations: [
    ChartsComponent,
    Graph1Component,
    Graph5Component,
    Graph7Component,
    Graph9Component,
    Graph10Component
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    FormWizardModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbButtonsModule,
    ChecklistModule
  ],

  exports: [
    ChartsComponent
  ]
})

export class ChartsModule {

}
