import { Injectable } from '@angular/core'
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConfigService } from 'app/core/services/app-config.service';
import { I18NService } from './i18n.service';

@Injectable()
export class MetaService {

  private activeLang: any;
  private supportedLangs: Array<string> = ['en', 'fr'];
  private shareDefaultImg: string;
  private creator: string;

  constructor(
    appConfigService: AppConfigService,
    private meta: Meta,
    private router: Router,
    private i18NService: I18NService
  ) {
    this.activeLang = i18NService.getActiveLang();
    this.shareDefaultImg = appConfigService.appConfig['shareConfig']['defaultImg'];
    this.i18NService.getSingleTranslation('share.soam.creator')
      .then((translation) => {
        this.creator = translation;
      });
  }

  resetMetas() {
    this.meta.removeTag('name="render:status_code"');
    this.meta.removeTag('name="title"');
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="creator"');
    this.meta.removeTag('name="publisher"');
    this.meta.removeTag('name="distribution"');
    this.meta.removeTag('name="author"');
    let countries = this.meta.getTags('name="country"');
    countries.forEach(() => {
      this.meta.removeTag('name="country"');
    });
    this.meta.removeTag('name="lang"');
    this.meta.removeTag('itemprop="name"');
    this.meta.removeTag('itemprop="description"');
    this.meta.removeTag('name="twitter:card"');
    this.meta.removeTag('name="twitter:site"');
    this.meta.removeTag('name="twitter:title"');
    this.meta.removeTag('name="twitter:description"');
    this.meta.removeTag('name="twitter:image:src"');
    this.meta.removeTag('property="og:image"');
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:type"');
    this.meta.removeTag('property="og:url"');
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('property="og:site_name"');
    this.meta.removeTag('name="id"');
    this.meta.removeTag('property="og:id"');
    this.meta.removeTag('name="date"');
    this.meta.removeTag('property="og:date"');
    this.meta.removeTag('name="resource-type"');
    this.meta.removeTag('property="og:resource-type"');
    let metas = this.meta.getTags('name="document-title"');
    metas.forEach(() => {
      this.meta.removeTag('name="document-title"');
      this.meta.removeTag('name="document-language"');
      this.meta.removeTag('name="document-url"');
      this.meta.removeTag('name="document-content"');
      this.meta.removeTag('property="og:document-title"');
      this.meta.removeTag('property="og:document-language"');
      this.meta.removeTag('property="og:document-url"');
      this.meta.removeTag('property="og:document-content"');
    });
    return true;
  }

  addMetas(
    title: string, 
    description: string,
    id?: string,
    publisher?: string,
    author?: string,
    type?: string,
    country?: string,
    year?: string,
    documents?: Array<any>,
    image?: string
  ) {
    const desc = description && description.length > 150 ? description.substring(0, 147) + '...' : description;
    //const url = this.router.url;
    const url = document.location.href;
    this.meta.addTag({ name: 'title', content: title });
    this.meta.addTag({ name: 'description', content: desc });
    this.meta.addTag({ name: 'creator', content: this.creator });
    if (publisher) {
      this.meta.addTag({ name: 'publisher', content: publisher });
    } else {
      this.meta.addTag({ name: 'publisher', content: this.creator });
    }
    this.meta.addTag({ name: 'distribution', content: 'global' });
    if (author) {
      this.meta.addTag({ name: 'author', content: author });
    } else {
      this.meta.addTag({ name: 'author', content: this.creator });
    }
    if (country) {
      country.split(',').forEach((c) => {
        this.meta.addTag({ name: 'country', content: c });
      }); 
    }
    this.meta.addTag({ name: 'lang', content: this.activeLang });
    this.meta.addTag({ itemprop: 'name', content: title });
    this.meta.addTag({ itemprop: 'description', content: description });
    this.meta.addTag({ name: 'twitter:card', content: 'summary' });
    this.meta.addTag({ name: 'twitter:site', content: '@AcuteMalnut' });
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:description', content: desc});
    if (image) {
      this.meta.addTag({ name: 'twitter:image:src', content: image});
      this.meta.addTag({ property: 'og:image', content: image });
    } else {
      this.meta.addTag({ name: 'twitter:image:src', content: this.shareDefaultImg});
      this.meta.addTag({ property: 'og:image', content: this.shareDefaultImg });
    }
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'og:url', content: url });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({ property: 'og:site_name', content: this.creator });
    if (id) {
      this.meta.addTag({ name: 'id', content: id });
      this.meta.addTag({ property: 'og:id', content: id });
    }
    if (year) {
      this.meta.addTag({ name: 'date', content: year });
      this.meta.addTag({ property: 'og:date', content: year });
    }
    if (type) {
      this.meta.addTag({ name: 'resource-type', content: type });
      this.meta.addTag({ property: 'og:resource-type', content: type });
    }
    if (documents) {
      documents.forEach((d) => {
        this.meta.addTag({ name: 'document-title', content: d.fields.title });
        this.meta.addTag({ name: 'document-language', content: d.fields.language });
        this.meta.addTag({ name: 'document-url', content: d.fields.fileUrl });
        this.meta.addTag({ name: 'document-content', content: d.fields.fileUrl });
        this.meta.addTag({ property: 'og:document-title', content: d.fields.title });
        this.meta.addTag({ property: 'og:document-language', content: d.fields.language });
        this.meta.addTag({ property: 'og:document-url', content: d.fields.fileUrl });
        this.meta.addTag({ property: 'og:document-content', content: d.fields.fileUrl });
      });
    }
  }

  addSingleNotFoundMeta() {
    this.meta.addTag({ name: 'render:status_code', content: '404' });
  }
}
