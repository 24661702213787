import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title }     from '@angular/platform-browser';
import { MetaService } from 'app/service/meta.service';
import { StaticPagesService } from '../service/static-pages.service';
import { I18NService } from '../service/i18n.service';
import { ScreenSizeService } from '../service/screen-size.service';
import '../../styles/components/static-section.scss';
import * as $ from 'jquery/dist/jquery.min.js';


@Component({
    templateUrl: './static-pages.component.html',
    encapsulation: ViewEncapsulation.None,
    selector: 'static-pages'
})

export class StaticPagesComponent implements OnInit {
    sections = [];
    height: number;
  	width: number;
    url: string;
    title: string;
    content: string;
    params: any;
    loading: boolean = true;
    showVisme: boolean =false;
    classM: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router, 
        private staticPagesService: StaticPagesService,
        private screenSizeService: ScreenSizeService,
        private metaService: MetaService,
        private titleService: Title,
        private i18NService: I18NService
      ) {
    }

    ngOnInit() {
    this.classM = "mt-5 static"
    this.showVisme= false;
    	this.height = this.screenSizeService.getHeight();
    	this.width = this.screenSizeService.getWidth();
      this.params = this.route.params;
      this.params.subscribe(params => {
        if (params['name']) {
          this.staticPagesService.getByURL(params['name'])
            .then((staticPage) => {
              if (staticPage) {
                
                this.i18NService.getSingleTranslation('share.soam.text')
                  .then((translation) => {
                    this.titleService.setTitle(staticPage.title + translation);
                    this.metaService.resetMetas();
                    this.metaService.addMetas(
                      staticPage.title + translation,
                      staticPage.title + translation,
                    );
                  });
                  this.title = staticPage.title;
                  //this.content = staticPage.content; 
                  this.sections = [];
                  if (staticPage.sections){
                   if (staticPage.sections.length > 0){                
                   this.sections = staticPage.sections.map(section => section.fields);                  
                  }}
                  this.content = staticPage.contentNew;                  
                  this.url = params['name'];
                  if(this.url === 'meriam-test'){
                    document.getElementsByTagName('footer')[0].getElementsByClassName('container')[0].remove()
                    this.classM='m-0'
                  }
                  this.loading = false;
                  if(this.content.includes("{{myvisme}}")){                   
                    this.showVisme=true;
                    this.content =this.content.replace("{{myvisme}}",'<div id="previsme"><div class="visme_d" data-url="4dy9od8j-adaptations-to-the-standard-protocol-and-related-evidence-in-the-wca-region" data-w="800" data-h="9504" data-domain="my"></div></div><p style="width:220px !important;border-radius:3px !important;padding:3px !important;font-size:12px !important;font-family:Arial, sans-serif !important;color:#314152 !important;white-space:nowrap !important">Made with <a href="https://www.visme.co/make-infographics?utm_source=CTA&amp;utm_medium=Embed" target="_blank" style="font-weight:600 !important;text-decoration:none !important;font-size:12px !important;font-family:Arial, sans-serif !important;color:#314152 !important;white-space:nowrap !important">Visme Infographic Maker</a></p>');
                    var j = document.createElement("script");j.type = "text/javascript";j.src = '//my.visme.co/visme-embed.js';document.getElementsByTagName("body")[0].appendChild(j);
                  }
               } else {
                this.router.navigate(['404']);
              }
            })
            .catch((e) => {              
              this.router.navigate(['404']);
            });
        } else {          
          this.router.navigate(['404']);
        }
      });
    }

}
