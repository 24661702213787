import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { CountryDataModule } from '../country-data/country-data.module';
import { MapModule } from '../map/map.module';
import { CountriesRouterModule } from './countries-router.module';
import { CountriesComponent } from 'app/countries/countries.component';
import { CountryComponent } from 'app/countries/country.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faAngleDown } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowLeft,faAngleDown )

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    CountriesComponent,
    CountryComponent
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    SharedModule,
    CountriesRouterModule,
    FormsModule,
    CountryDataModule,
    MapModule,
    FontAwesomeModule
  ],

  exports: [
  ]
})

export class CountriesModule {

}
