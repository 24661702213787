import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from 'app/core/services/app-config.service';
import { ScreenSizeService } from 'app/service/screen-size.service';
import { I18NService } from '../service/i18n.service';
import { DatasourceService } from '../service/datasource.service';
import * as _ from 'underscore';

@Component({
  templateUrl: './map.component.html',
  selector: 'map'
})
export class MapComponent implements OnInit {
  baseUrl: string;
  hostUrl: string;
  tableauImg: string;
  tableauName: string;
  tableauScript: any = null;
  tableauDiv: any = null;
  subcategories: Array<any> = [];
  percentages: Array<any> = [];
  numerics: Array<any> = [];
  booleans: Array<any> = [];
  datafields: Array<any> = [];
  originalPercentages: Array<any> = [];
  originalNumerics: Array<any> = [];
  originalBooleans: Array<any> = [];
  filterSubcategory: Array<any> = [];
  allYears: Array<any> = [];
  measure: string = null;
  activeLang: string;
  year: number = null;
  selectedNYear: any;
  selectedCategory: null;
  subNationalCountry: Array<any> = [];
  typeOfprogram: Array<any> = [];
  subNationalYear: Array<any> = [];
  selectedNationalIndicator: any;
  selectedNationalIndicatorObJect: any; 
  selectedSubCountry: any;
  loading: boolean = false;
  showTableau: boolean = false;
  viewIsNational: boolean = true;
  viewSubNational: boolean = false;
  subYear: any;
  subTOP: any;
  subcountry: any;
  currentSubItem: any;

  @Input() country: string;

  @Input() mapThumbnail: any;

  constructor(
    private appConfigService: AppConfigService,
    private i18nService: I18NService,
    private screenSizeService: ScreenSizeService,
    private datasourceService: DatasourceService
  ) {
    this.year = appConfigService.appConfig['data']['defaultYear'];
  }

  ngOnInit() {
    this.loading = true;
    this.activeLang = this.i18nService.getActiveLang();
    this.baseUrl = this.appConfigService.appConfig['tableauConfig']['baseUrl'];
    let scriptElement = document.createElement('script');
    scriptElement.src = this.appConfigService.appConfig['tableauConfig']['src'];
    this.tableauScript = scriptElement;
    if (this.country) {
      this.selectedSubCountry = this.country;
      this.subcountry = this.selectedSubCountry;
    }
    this.tableauImg = this.appConfigService.appConfig['tableauConfig']['mapSection']['img'];
    this.tableauDiv = document.getElementById('tableau');
    this.getCategories();
  }

  nationalData() {
  }

  subNationalData() {
  }

  filterDataTypes(subcategory: any) {
    this.numerics = this.tagArray(this.numerics, 'numeric');
    this.percentages = this.tagArray(this.percentages, 'percentage');
    this.booleans = this.tagArray(this.booleans, 'boolean');
    this.filterSubcategory = this.numerics.concat(this.percentages, this.booleans);
    this.filterSubcategory = this.filterSubcategory.filter((d) => d.subcategory.toUpperCase()
    === subcategory.name.toUpperCase());
    this.selectedNationalIndicator = _.first(this.filterSubcategory).title;
    this.selectedNationalIndicatorObJect = _.first(this.filterSubcategory);
    this.callTableauWithFilter(_.first(this.filterSubcategory));
    this.setYears(_.first(this.filterSubcategory))
    this.showTableau = true;
  }

  setYears(item) {
    if (!_.isUndefined(item)) {
      this.loading = true;
      this.datasourceService.getNationallYear().then((response) => {
       // console.log('all Years',response )
        this.allYears = response
      })
      this.selectedNYear = this.year;
      this.callTableauWithFilter(item);
      setTimeout(() => {
       
       this.loading = false;
      }, 500);

    }

  }
  tagArray(arr, type) {
    arr.forEach((item) => {
      item.id = Math.floor(Math.random() * 100000 + 1);
      item.tag = type;
    });
    return arr;
  }

  callTableauWithFilter(item) {
    this.showTableau = true;
    this.loading = true;
    this.measure = item.name;
    if (this.selectedNYear) {
      this.year = this.selectedNYear;
    }
    if (_.isNull(this.selectedNYear)) {
      this.year = null;
    }

    if (item.tag === 'boolean') {
      this.hostUrl = this.appConfigService.appConfig['tableauConfig']['hostUrl'];
      this.tableauName = this.appConfigService.appConfig['tableauConfig']['mapSection']['booleanName'];
    } else if (item.tag === 'numeric') {
      this.hostUrl = this.appConfigService.appConfig['tableauConfig']['hostUrl'];
      this.tableauName = this.appConfigService.appConfig['tableauConfig']['mapSection']['numericName'];
    } else if (item.tag === 'percentage') {
      this.hostUrl = this.appConfigService.appConfig['tableauConfig']['hostUrl'];
      this.tableauName = this.appConfigService.appConfig['tableauConfig']['mapSection']['percentageName'];
    } else if (item.tag === 'subnational') {
      this.measure = null;
      this.hostUrl = this.appConfigService.appConfig['tableauConfig']['hostUrl'];
      this.tableauName = this.appConfigService.appConfig['tableauConfig']['mapSection']['subnationalName'];
    }
    let iframeElement = this.tableauDiv.getElementsByTagName('iframe')[0];
    if (!iframeElement) {

      // First filter selection
      this.selectedNYear = this.year;
      let vizElement = this.tableauDiv.getElementsByTagName('object')[0];
      vizElement.style.width = '100%';
      if (this.screenSizeService.isLg() || this.screenSizeService.isXl()) {
        vizElement.style.height = '800px';
      } else {
        vizElement.style.height = this.tableauDiv.offsetWidth * 0.75 + 'px';
      }
      vizElement.parentNode.insertBefore(this.tableauScript, vizElement);
    } else {
      let newHostUrl = this.baseUrl + this.tableauName + '?:embed=y&:showVizHome=no&:host_url=';
      newHostUrl += this.hostUrl + '&:embed_code_version=3&:tabs=no&:toolbar=no&:animate_transition=yes&:display_static_image=yes&:scrolling=no';

      if (this.subYear) {
        newHostUrl += '&Year=' + this.subYear;
      }
      if (this.subcountry) {

        newHostUrl += '&ISO code=' + this.subcountry;
      }
      if (this.subTOP) {
        newHostUrl += '&OTP%20code=' + this.subTOP;
      }
      if (this.measure) {
        newHostUrl += '&:display_spinner=yes&:display_overlay=yes&:display_count=yes&:linktarget=_blank&Measure=' + this.measure;
      }
      if (this.country) {
        newHostUrl += '&ISO=' + this.country;
      }
      newHostUrl += '&Language=' + (this.activeLang === 'fr' ? 'French' : 'English');
      if (this.year) {
        newHostUrl += '&YearM=' + this.year;
      }
      newHostUrl += '&:loadOrderID=0';
      iframeElement.setAttribute('src', newHostUrl);
    }
    if (this.viewIsNational) {
    } else if (!this.viewIsNational) {
      this.showTableau = true;
    }
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  setSubnational() {

    this.showTableau = true;
    let item = { tag: 'subnational' };
    this.datasourceService.getSubNationalData().then((response) => {
      this.subNationalCountry = this.datasourceService.subNationalCountry(response);
      this.getTypeProgram();
      this.getSubNationalYear();

    });

    //this.callTableauWithFilter(item);
  }


  getTypeProgram() {
    this.datasourceService.getTypeProgram().then((response) => {
      this.typeOfprogram = response;
      this.callTableauWithFilter({ tag: 'subnational', name: 'subnational' })
    });
  }

  getSubNationalYear() {
    this.datasourceService.getsubNationalYear().then((response) => {
      this.subNationalYear = response;
    });
  }
  _click = (elem) => {
    var evt = new MouseEvent('click');
    elem.dispatchEvent(evt);
  };



  getCategories() {
    this.datasourceService.getDataSubcategories(this.activeLang).then((categories) => {
      categories.shift()
      this.datafields = categories;
      //console.log(categories);
      this.getNumerics();
    });
  }

  getNumerics() {
    const endPoint = this.country
      ? this.datasourceService.getFieldsOfType(this.activeLang, 'numeric', this.country)
      : this.datasourceService.getFieldsOfType(this.activeLang, 'numeric');
    endPoint.then((numerics) => {
      this.numerics = numerics;
      this.originalNumerics = numerics;
      this.getPercentage();
    });
  }

  getPercentage() {
    const endPoint = this.country
      ? this.datasourceService.getFieldsOfType(this.activeLang, 'percentage', this.country)
      : this.datasourceService.getFieldsOfType(this.activeLang, 'percentage');
    endPoint.then((percentages) => {
      this.percentages = percentages;
      this.originalPercentages = percentages;
      this.getBoleans();
    });
  }

  getBoleans() {
    let endPoint = this.country
      ? this.datasourceService.getFieldsOfType(this.activeLang, 'boolean', this.country)
      : this.datasourceService.getFieldsOfType(this.activeLang, 'boolean');
    endPoint.then((booleans) => {
      this.booleans = booleans;
      this.originalBooleans = booleans;
      this.filterCategories();
    });
  }

  filterCategories() {
    let filteredSubcategories = [];
    let i = 0;
    const allFilters = this.numerics.concat(this.percentages, this.booleans);


    this.datafields.forEach((d) => {
      allFilters.forEach((f) => {
        if (f.subcategory === d.name) {
          let inArray = false;
          filteredSubcategories.forEach((fs) => {
            if (fs.name === d.name) {
              inArray = true;
              return false;
            }
          });
          if (!inArray) {
            d.id = i++;
            filteredSubcategories.push(d);
          }
          return false;
        }
      });
    });

    this.subcategories = _.sortBy(filteredSubcategories, this.dynamicSort('name'));   
    this.selectedCategory = _.first(this.subcategories).name;
    this.filterDataTypes(_.first(this.subcategories));
    i = 0;
    this.loading = false;
  }

  setCounrty($event) {
    if (!_.isUndefined($event)) {
      this.subcountry = $event.countryIsoCode;
      $event.tag = 'subnational'
      this.callTableauWithFilter($event)
    }

  }

  setTOP($event) {
    if (!_.isUndefined($event)) {
      this.subTOP = $event.SFP_OTP;
      $event.tag = 'subnational'
      this.callTableauWithFilter($event)
    }

  }


  setYear($event) {
    this.loading=true;
    if (!_.isUndefined($event)) {
      this.subYear = $event.year;
      $event.tag = 'subnational'
      this.callTableauWithFilter($event)
    }
  }

  clearSubnationalData() {
    this.subYear = '';
    this.subcountry = '';
    this.subTOP = ''
  }

  clearNationalData() {
    this.filterSubcategory = [];
    this.allYears = [];
  }

  ClearNatonalCategory() {

    this.selectedNationalIndicator = null;
  }

  clearSubYear($event) {
    let newitem = {
      tag: 'subnational',
      name: 'subnational'
    }
    this.subYear = null;
    this.callTableauWithFilter(newitem)
  }


  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
}


