import { Injectable } from '@angular/core'
import { AppConfigService } from 'app/core/services/app-config.service'

@Injectable()
export class ScreenSizeService {

  sizes: any;

  constructor(private appConfigService: AppConfigService) {
    this.sizes = this.appConfigService.appConfig['sizes']
  }

  getWidth() {
    return window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
  }

  getHeight() {
    return window.screen.height
  }

  isXl(){
    let width = this.getWidth()
    return width >= this.sizes.breakpointXl
  }

  isLg(){
    let width = this.getWidth()
    return width <= this.sizes.breakpointXl && width > this.sizes.breakpointLg
  }

  isMd(){
    let width = this.getWidth()
    return width <= this.sizes.breakpointLg && width > this.sizes.breakpointMd
  }

  isSm(){
    let width = this.getWidth()
    return width <= this.sizes.breakpointMd && width > this.sizes.breakpointSm
  }

  isXs(){
    let width = this.getWidth()
    return width <= this.sizes.breakpointSm
  }

  getThumbnailColWidth(){
    let sizes = this.sizes
    if(this.isXl()){
        return sizes.containerWidthXl/4
    }
    else if (this.isLg()){
        return sizes.containerWidthLg/4
    }
    else if (this.isMd()){
      return sizes.containerWidthMd/2
    }
    else if (this.isSm()){
      return sizes.containerWidthSm
    }
    else {
      return this.getWidth()
    }
  }

  getDocumentImgWidth(){
    let sizes = this.sizes
    if(this.isXl()){
        return sizes.containerWidthXl/3
    }
    else if (this.isLg()){
        return sizes.containerWidthLg/3
    }
    else if (this.isMd()){
      return sizes.containerWidthMd/3
    }
    else if (this.isSm()){
      return sizes.containerWidthSm
    }
    else {
      return this.getWidth()
    }
  }

}
