import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LangResolver } from 'app/service/lang.resolver';
import { CountriesComponent } from './countries.component';
import { CountryComponent } from './country.component';

@NgModule({
  imports: [  
    RouterModule.forChild([
      { 
        path: 'countries',
        redirectTo: 'en/countries',
        pathMatch: 'full'
      },
      { 
        path: ':lang/countries', component: CountriesComponent,
        resolve: {
          lang: LangResolver
        }
      },
      { 
        path: 'countries/:country',
        redirectTo: 'en/countries/:country',
        pathMatch: 'full'
      },
      { path: ':lang/countries/:country', component: CountryComponent,
        resolve: {
          lang: LangResolver
        } 
      }
    ])
  ],
  exports: [
    RouterModule
  ]
})

export class CountriesRouterModule {

}
