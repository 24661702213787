import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { FooterComponent } from 'app/footer/footer.component';
import { PartnerLogoSliderModule } from './components/partner-logo-slider/partner-logo-slider.module';

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    FooterComponent
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    SharedModule,
    PartnerLogoSliderModule,
    RouterModule
  ],

  exports: [
    FooterComponent
  ]
})

export class FooterModule {

}
