import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class I18NService {

  private activeLang: any;
  private supportedLangs: Array<string> = ['en', 'fr'];

  constructor(
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang(this._getDefaultLang());
    this.activeLang = this._getLangLocalStorage();
    this.translate.use(this.activeLang);
  }

  private _getDefaultLang() {
    if (navigator.language) {
      let defaultLang =
        navigator.language.startsWith('en-') ? 'en' : navigator.language;
      return this.supportedLangs.indexOf(defaultLang) > -1 ? defaultLang : 'en';
    } else {
      return 'en';
    }
  }

  private _getLangLocalStorage() {
    return window.localStorage && window.localStorage.getItem('lang') ?
      window.localStorage.getItem('lang') : this._getDefaultLang();
  }

  getActiveLang() {
    return this.activeLang;
  }

  setLang(lang: string) {
    window.localStorage.setItem('lang', lang);
    window.location.reload();
  }

  getSingleTranslation(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.translate.get(key).subscribe(data => resolve(data));
    });
  }

}
