import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormWizardModule} from 'angular2-wizard';;
import {SharedModule} from 'app/shared/shared.module';
import {MapComponent} from 'app/map/map.component';
import * as _ from 'underscore';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  // Components, Pipes, Directive
  declarations: [MapComponent],

  providers: [],

  // Modules
  imports: [CommonModule, FormWizardModule, SharedModule, NgSelectModule, FormsModule,ReactiveFormsModule,FontAwesomeModule],

  exports: [MapComponent]
})
export class MapModule {
  
}
