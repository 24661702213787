import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ShareButtonModule } from '@ngx-share/button';
import { SharedModule } from 'app/shared/shared.module';
import { EventsRouterModule } from './events-router.module';
import { EventsComponent } from 'app/events/events.component';
import { EventDetailComponent } from 'app/events/event-detail.component';
import { BlogDetailComponent } from 'app/events/blog-detail.component';
import * as _ from 'underscore';

@NgModule({
  // Components, Pipes, Directive
  declarations: [
    EventsComponent,
    EventDetailComponent,
    BlogDetailComponent
  ],

  providers: [],

  // Modules
  imports: [
    CommonModule,
    SharedModule,
    EventsRouterModule,
    FormsModule,
    HttpClientModule,
    ShareButtonModule
  ],

  exports: [
    EventsComponent,
    EventDetailComponent ,
    BlogDetailComponent ]
})

export class EventsModule {

}
